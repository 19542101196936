import { Injectable } from '@angular/core';
import {environment} from "@env/environment";
import {HttpClient, HttpResponse} from '@angular/common/http';
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Observable} from "rxjs";
import {AggregatedData} from "@app/modules/sales/informe/pages/informe-dinamico/informe-dinamico";
import {InformeOrdenCompraModel} from '@app/shared/model/informe-orden-compra.model';

@Injectable()
export class InformesService {

  urlBase = environment.api.base_url + environment.api.modules.informes;

  constructor(private http: HttpClient) {
  }

  dinamicoHistoricos(searchRequest: SearchRequest): Observable<PaginatedResponse<AggregatedData>> {
    return this.http.post<PaginatedResponse<AggregatedData>>(this.urlBase + '/dinamico/historico', searchRequest);
  }

  dinamicoPredicciones(searchRequest: SearchRequest): Observable<PaginatedResponse<AggregatedData>> {
    return this.http.post<PaginatedResponse<AggregatedData>>(this.urlBase + '/dinamico/predicciones', searchRequest);
  }

  anyoMinimoHistoricos(): Observable<number> {
    return this.http.get<number>(this.urlBase + '/dinamico/anyo_minimo/historico');
  }

  anyoMinimoPredicciones(): Observable<number> {
    return this.http.get<number>(this.urlBase + '/dinamico/anyo_minimo/prediccion');
  }

  anyoMaximo(): Observable<number> {
    return this.http.get<number>(this.urlBase + '/dinamico/anyo_maximo');
  }

  dinamicoExportacion(searchRequest: SearchRequest): Observable<HttpResponse<Blob>> {
    return this.http.post(this.urlBase + '/dinamico/export', searchRequest, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  historicoDinamicoExportacion(searchRequest: SearchRequest, formato?: string): Observable<unknown> {
    if (formato) {
      return this.http.post(this.urlBase + '/dinamico/historico/export/' + formato, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    } else {
      return this.http.post(this.urlBase + '/dinamico/historico/export', searchRequest, { observe: 'response', responseType: 'blob' });
    }
  }

  prediccionDinamicoExportacion(searchRequest: SearchRequest, formato?: string): Observable<unknown> {
    if (formato) {
      return this.http.post(this.urlBase + '/dinamico/prediccion/export/' + formato, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    } else {
      return this.http.post(this.urlBase + '/dinamico/prediccion/export', searchRequest, { observe: 'response', responseType: 'blob' });
    }
  }

  ordenCompra(ordenCompraModel: InformeOrdenCompraModel): Observable<unknown> {
    return this.http.post(this.urlBase + '/orden_compra', ordenCompraModel, { observe: 'response', responseType: 'blob' });
  }

}
