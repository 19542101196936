import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {MainState} from "@app/core/store/main.store";
import {CuentaModel} from "@app/shared/model/cuenta.model";

@Component({
  selector: 'app-cambio-cuenta',
  templateUrl: './cambio-cuenta.component.html'
})
export class CambioCuentaComponent implements OnInit {

  constructor(private router: Router, private store: Store) {
  }

  ngOnInit(): void {
    const cuentaSeleccionado: CuentaModel = this.store.selectSnapshot(MainState.cuenta);

    //setTimeout(() => this.router.navigate([`/cuenta/${cuentaSeleccionado.id}`]), 1000);

    this.router.navigate([`/main`])
  }

}
