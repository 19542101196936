import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AzComponentsModule} from "@azigrene/components";
import {AzDataManagerModule} from '@azigrene/data-manager';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {ProductService} from '@app/shared/service/product.service';
import {EnumsService} from '@app/shared/service/enum.service';
import {StoreService} from '@app/shared/service/store.service';
import {TokenInterceptor} from '@app/core/interceptor/token.interceptor';
import {HistoricalService} from '@app/shared/service/historical.service';
import {ModelService} from '@app/shared/service/model.service';
import {TypeModelService} from '@app/shared/service/type-model.service';
import {DialogoImportacionComponent} from '@app/shared/components/dialogo-importacion/dialogo-importacion.component';
import {StepperComponent} from '@app/shared/components/stepper/stepper.component';
import {AzDownloaderModule} from '@azigrene/downloader';
import {DataImportExportComponent} from '@app/shared/components/data-import-export/data-import-export.component';
import {PasteImportComponent} from '@app/shared/components/paste-import/paste-import.component';
import {FormFileComponent} from '@app/shared/components/form-file/form-file.component';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {DateRangeSelectorComponent} from '@app/shared/components/date-range-selector/date-range-selector.component';
import {AzPipesModule} from '@azigrene/pipes';
import {MomentModule} from 'ngx-moment';
import {PredictionService} from './service/prediction.service';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartModule} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import zipcelx from 'zipcelx';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import {CuentaMiddleware} from '@app/core/middleware/cuenta.middleware';
import {WeatherStationService} from '@app/shared/service/weather-station.service';
import {UserService} from '@app/shared/service/user.service';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AzValidConditionDirective, AzValidControlDirective, AzValidDirective} from '@app/shared/directives/az-valid.directive';
import {FormSectionComponent} from '@app/shared/components/form-section/form-section.component';
import {AccountService} from '@app/shared/service/account.service';
import {AccountTypeService} from '@app/shared/service/account-type.service';
import {DailyWeatherDataService} from '@app/shared/service/daily-weather-data.service';
import {HourlyWeatherDataService} from '@app/shared/service/hourly-weather-data.service';
import {CalendarUseService} from '@app/shared/service/calendar-use.service';
import {CalendarUseTypeDayService} from '@app/shared/service/calendar-use-type-day.service';
import {CalendarUseTypeDayHourService} from '@app/shared/service/calendar-use-type-day-hour.service';
import {DateSelectorComponent} from '@app/shared/components/date-selector/date-selector.component';
import {DragToSelectModule} from 'ngx-drag-to-select';
import {InformesService} from '@app/shared/service/informes.service';
import {ProductFieldService} from '@app/shared/service/product-field.service';
import {UsageCalendarService} from '@app/shared/service/usage-calendar.service';
import {CalendarHoursUseComponent} from '@app/modules/consumption/calendar/components/horas-calendario/calendar-hours-use.component';
import {ApiTokenService} from '@app/shared/service/api-token.service';
import {ProductDetailChartsService} from '@app/modules/sales/products/pages/product-detail/product-detail-charts.service';
import {PredictionProcessService} from "@app/shared/service/prediction-process.service";
import {PredictionProcessProductService} from "@app/shared/service/prediction-process-product.service";
import {BreadcrumbComponent} from "@app/shared/components/breadcrumb/breadcrumb.component";
import {RecoverPasswordService} from "@app/shared/service/recover-password.service";
import {NewPasswordComponent} from "@app/auth/pages/new-password/new-password.component";


HC_exporting(Highcharts);
HC_exportData(Highcharts);

const sharedModules = [
  AzComponentsModule,
  FormsModule,
  ReactiveFormsModule,
  TranslocoModule,
  CommonModule,
  HttpClientModule,
  AzDataManagerModule,
  NzDatePickerModule,
  AzPipesModule,
  MomentModule,
  NgxChartsModule,
  ChartModule,
  AzDownloaderModule,
];

@NgModule({
  imports: [...sharedModules, CodemirrorModule, AngularSvgIconModule.forRoot(), DragToSelectModule.forRoot()],
  exports: [
    ...sharedModules,
    DataImportExportComponent,
    CalendarHoursUseComponent,
    NewPasswordComponent,
    FormSectionComponent,
    StepperComponent,
    DateRangeSelectorComponent,
    AzValidDirective,
    AzValidControlDirective,
    AzValidConditionDirective,
    DateSelectorComponent,
    BreadcrumbComponent
  ],
  declarations: [
    DialogoImportacionComponent,
    DataImportExportComponent,
    CalendarHoursUseComponent,
    NewPasswordComponent,
    FormSectionComponent,
    PasteImportComponent,
    FormFileComponent,
    DateRangeSelectorComponent,
    DateSelectorComponent,
    AzValidDirective,
    AzValidControlDirective,
    AzValidConditionDirective,
    StepperComponent,
    BreadcrumbComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CuentaMiddleware, multi: true},
    AzValidDirective,
    AzValidControlDirective,
    AzValidConditionDirective,
    ProductDetailChartsService,
    ProductService,
    ProductFieldService,
    EnumsService,
    StoreService,
    CalendarUseService,
    UsageCalendarService,
    CalendarUseTypeDayService,
    CalendarUseTypeDayHourService,
    HistoricalService,
    TypeModelService,
    ModelService,
    PredictionService,
    PredictionProcessService,
    PredictionProcessProductService,
    WeatherStationService,
    DailyWeatherDataService,
    HourlyWeatherDataService,
    InformesService,
    UserService,
    AccountTypeService,
    ApiTokenService,
    AccountService,
    RecoverPasswordService
  ],
  entryComponents: [DialogoImportacionComponent],
})
export class SharedModule {
  constructor(private translateService: TranslocoService) {
    Highcharts.getOptions().lang.downloadXLS = this.translateService.translate('highcharts.descargar-xlsx');
    Highcharts.getOptions().lang.downloadCSV = this.translateService.translate('highcharts.descargar-csv');
    Highcharts.getOptions().lang.downloadJPEG = this.translateService.translate('highcharts.descargar-jpeg');
    Highcharts.getOptions().lang.downloadPDF = this.translateService.translate('highcharts.descargar-pdf');
    Highcharts.getOptions().lang.downloadPNG = this.translateService.translate('highcharts.descargar-png');
    Highcharts.getOptions().lang.downloadSVG = this.translateService.translate('highcharts.descargar-svg');
    Highcharts.getOptions().lang.viewFullscreen = this.translateService.translate('highcharts.ver-pantalla-completa');
    Highcharts.getOptions().lang.printChart = this.translateService.translate('highcharts.imprimir-grafica');
    Highcharts.getOptions().lang.viewData = this.translateService.translate('highcharts.ver-datos');
    Highcharts.getOptions().lang.hideData = this.translateService.translate('highcharts.esconder-datos');

    Highcharts.getOptions().exporting.menuItemDefinitions.downloadXLS = {
      textKey: 'downloadXLS',
      onclick: function () {
        const rows = this.getDataRows(true);

        const xlsxRows = rows.slice(1).map(function (row) {
          return row.map(function (column) {
            return {
              type: typeof column === 'number' ? 'number' : 'string',
              value: column,
            };
          });
        });

        zipcelx({
          filename: 'chart-export',
          sheet: {
            data: xlsxRows,
          },
        });
      },
    };
  }
}
