import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {Observable} from "rxjs";
import {LoginResponse} from "@app/auth/model/login";

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) {
  }

  auth(az_ml_auth: { email: string, password: string }): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(environment.api.auth, az_ml_auth);
  }

}
