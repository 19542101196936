import {appConfig} from "@config/app.config.prod";


export const environment = {
  production: true,
  api: {
    base_url: appConfig.apiUrl + '/main',
    modules: {
      account: '/cuenta',
      type_account: "/tipo_cuenta",
      integracion_token: '/cuenta/{cuentaId}/integracion_token',
      calendar_use: '/cuenta/{cuentaId}/calendarios_usos',
      calendar_use_day: '/cuenta/{cuentaId}/calendarios_usos_dias',
      calendar_use_type_day: '/cuenta/{cuentaId}/calendarios_usos_tipos_dia',
      calendar_use_type_day_hour: '/cuenta/{cuentaId}/calendarios_usos_tipos_dia_hora',
      user: '/user',
      product: '/cuenta/{cuentaId}/product',
      product_field: '/cuenta/{cuentaId}/producto_campo',
      tienda: '/cuenta/{cuentaId}/tienda',
      historico_ventas : '/cuenta/{cuentaId}/historico_ventas',
      informes : '/cuenta/{cuentaId}/informes',
      type_model: "/cuenta/{cuentaId}/tipo_modelo",
      prediction : '/cuenta/{cuentaId}/predicciones',
      process_prediction : '/cuenta/{cuentaId}/predicciones_procesos',
      process_prediction_product : '/cuenta/{cuentaId}/predicciones_procesos_productos',
      daily_weather_data: '/datos_meteorologicos_diarios',
      hourly_weather_data: '/datos_meteorologicos_horarios',
      weather_station: '/estacion_meteorologica',
      model : '/cuenta/{cuentaId}/modelo'
    },
    auth: appConfig.apiUrl + '/auth',
    recover_password: appConfig.apiUrl + '/recover_password',
    sso: appConfig.apiUrl + '/sso',
    ssoLogout: appConfig.apiUrl + '/sso-logout',
  }
};
