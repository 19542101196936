import {Injectable} from "@angular/core";
import {PredictionModel} from "@app/shared/model/prediction.model";
import {HistoricalModel} from "@app/shared/model/historical.model";
import {DateUtils} from "@app/shared/utils";
import {BarVertical2DModel} from "@app/shared/model/ngx-charts.model";
import moment from "moment";
import {Store} from "@ngxs/store";

@Injectable()
export class ProductDetailChartsService {

  constructor(private store: Store) {
  }

  createChartHistorico(historicos: HistoricalModel[], years:number[]): BarVertical2DModel[] {
    const MESES = 12;
    const serie :BarVertical2DModel[]=[];
    const nombreMeses = DateUtils.getListNameMonth(this.store);

    for(let i=0;i<MESES;i++) {
      const vertical:BarVertical2DModel = {
        name: nombreMeses[i],
        date: null,
        series: []
      }

      historicos.map(historico => {
        const fecha = new Date(historico.fecha);
        const found = years.find(element => element == fecha.getFullYear());

        if(fecha.getMonth()==i && found) {
          vertical.series.push(
            {name:fecha.getFullYear().toString(), value: historico.unidades.toString()}
          )
        }
      });
      serie.push(vertical);
    }

    return serie;
  }

  /**
   * Construimos las grafica de historico-prediciones mensual.
   * @param predictionsModels
   * @param historicos
   */
  createGraficaMes(predictionsModels: PredictionModel[], historicos: HistoricalModel[]): BarVertical2DModel[] {
    const addFecha:BarVertical2DModel[] = [];
    let grafica :BarVertical2DModel[];

    //Incluimos las predicciones
    grafica = predictionsModels.map(
      prediccion => ({
        name: moment(new Date(prediccion.fecha)).locale("es").format('YYYY-MMM'),
        date: new Date(prediccion.fecha),
        series: [{name: "Prediccion", value: prediccion.valor.toFixed(0)}]
      })
    );

    //Analizamos si la fecha ya existe, de ser así lo inclumos en el array correspondiente.
    //Sino existe lo creamos nuevo.
    historicos.map(
      historico => {
        let findDate = false;

        grafica.map(value => {
          if (DateUtils.isSameMonth(value.date, new Date(historico.fecha))) {
            findDate = true;
            value.series.push({name: "Historico", value: historico.unidades.toString()})
          }
        })
        if (!findDate) {
          grafica.push({
            name: moment(new Date(historico.fecha)).locale("es").format('YYYY-MMM'),
            date: new Date(historico.fecha),
            series: [{name: "Historico", value: historico.unidades.toString()}]
          })
        }
      }
    );

    grafica.sort((a, b) => a.date.getTime() - b.date.getTime());

    grafica.forEach((value, index, array) => {
      if (array.length - 1 > index) {
        let fecha = value.date;

        while (!DateUtils.isNextMonth(fecha, array[index + 1].date)) {
          fecha = DateUtils.getNextMonth(fecha);
          addFecha.push({name: moment(fecha).locale("es").format('YYYY-MMM'), date: fecha,series: [{name: "Historico", value: "0"}]})
        }
      }
    });

    grafica = grafica.concat(addFecha);
    grafica.sort((a, b) => a.date.getTime() - b.date.getTime());

    this.limitarMuestrasGraficas(grafica);

    return grafica;
  }

  /**
   * Construimos las grafica de historico-prediciones semanal.
   * @param predictionsModels
   * @param historicos
   */
  createGraficaSemanal(predictionsModels: PredictionModel[], historicos: HistoricalModel[]): BarVertical2DModel[] {
    const addFecha:BarVertical2DModel[] = [];
    let grafica :BarVertical2DModel[];

    //Incluimos las predicciones
    grafica = predictionsModels.map(
      prediccion => ({
        name: DateUtils.groupInWeek(new Date(prediccion.fecha), this.store),
        date: new Date(prediccion.fecha),
        series: [{name: "Prediccion", value: prediccion.valor.toFixed(0)}]
      })
    );

    //Analizamos si la fecha ya existe, de ser así lo inclumos en el array correspondiente.
    //Sino existe lo creamos nuevo.
    historicos.map(
      historico => {
        let findDate = false;

        grafica.map(value => {
          if (DateUtils.isSameWeek(value.date, new Date(historico.fecha))) {
            findDate = true;
            value.series.push({name: "Historico", value: historico.unidades.toString()})
          }
        })
        if (!findDate) {
          grafica.push({
            name: DateUtils.groupInWeek(new Date(historico.fecha), this.store),
            date: new Date(historico.fecha),
            series: [{name: "Historico", value: historico.unidades.toString()}]
          })
        }
      }
    );
    grafica.sort((a, b) => a.date.getTime() - b.date.getTime());

    grafica.forEach((value, index, array) => {
      if (array.length - 1 > index) {
        let fecha = value.date;

        while (!DateUtils.isNextWeek(fecha, array[index + 1].date)) {
          fecha = DateUtils.getNextWeek(fecha);
          addFecha.push({
            name: DateUtils.groupInWeek(fecha, this.store),
            date: fecha,
            series: [{name: "Historico", value: "0"}]
          })
        }
      }
    });

    grafica = grafica.concat(addFecha);
    grafica.sort((a, b) => a.date.getTime() - b.date.getTime());

    this.limitarMuestrasGraficas(grafica);

    return grafica;
  }

  /**
   * Limitar a 160 muestras. Dejamos las 160 finales.
   * @param grafica
   */
  limitarMuestrasGraficas(grafica:BarVertical2DModel[]):BarVertical2DModel[] {
    const NUMBER_MAX_MUESTRA = 160;

    if(grafica.length > NUMBER_MAX_MUESTRA) {
      grafica.splice(0, grafica.length - NUMBER_MAX_MUESTRA);
    }

    return grafica;
  }

  selectHistoricoHorarioFechas(predicciones:PredictionModel[], historicos: HistoricalModel[]):
    {historicos: HistoricalModel[], fechaIni: Date, fechaFin: Date} {
    const historicoHorario:HistoricalModel[] = [];
    let fechaIni: Date;
    let fechaFin: Date;

    if(predicciones.length>0) {
      fechaIni = new Date(predicciones[0].fecha);
      fechaFin = new Date(predicciones[predicciones.length-1].fecha);

      predicciones.map(p=> {
        const fecha = new Date(p.fecha);

        fechaIni = fechaIni.getTime()>fecha.getTime()?fecha: fechaIni;
        fechaFin = fechaFin.getTime()<fecha.getTime()?fecha: fechaFin;
      });
    } else {
      fechaIni = new Date(historicos[0].fecha);
      fechaFin = new Date(historicos[predicciones.length-1].fecha);
    }

    historicos.map(h=> {
      const fecha = new Date(h.fecha);

      if(fechaIni.getTime()<=fecha.getTime() && fechaFin.getTime()>=fecha.getTime()) {
        historicoHorario.push(h);
      }
    });

    return {
      historicos: historicoHorario,
      fechaIni: fechaIni,
      fechaFin: fechaFin
    };
  }

  selectHistoricoHorario(predicciones:PredictionModel[], historicos: HistoricalModel[]): HistoricalModel[] {
    return this.selectHistoricoHorarioFechas(predicciones, historicos).historicos;
  }

}
