import { Injectable } from '@angular/core';
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {ModelModel} from "@app/shared/model/model.model";
import {Observable} from "rxjs";
import {ModelBatchModel} from "@app/shared/model/model-batch.model";

@Injectable()
export class ModelService {

  urlBase = environment.api.base_url + environment.api.modules.model;
  constructor(private http: HttpClient) { }

  getAllPaginated(searchRequest: SearchRequest): Observable<PaginatedResponse<ModelModel>> {
    return this.http.post<PaginatedResponse<ModelModel>>(this.urlBase + '/all', searchRequest);
  }

  getAllPaginatedProduct(searchRequest: SearchRequest, productoId: number): Observable<PaginatedResponse<ModelModel>> {
    return this.http.post<PaginatedResponse<ModelModel>>(this.urlBase + `/${productoId}/all`, searchRequest);
  }

  delete(modeloId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + modeloId + '/delete');
  }

  create(model: ModelModel): Observable<ModelModel> {
    return this.http.post<ModelModel>(this.urlBase + '/create', model);
  }

  update(model: ModelModel): Observable<ModelModel> {
    return this.http.post<ModelModel>(this.urlBase + '/update', model);
  }

  trainer(model: ModelModel): Observable<ModelModel> {
    return this.http.post<ModelModel>(this.urlBase + '/trainer', model);
  }

  batchTrain(modelBatchModel: ModelBatchModel): Observable<boolean> {
    return this.http.post<boolean>(this.urlBase + '/batch_train', modelBatchModel);
  }

  lastPrediction(productoId: number, tiendaId: number): Observable<ModelModel> {
    const model= {productoId: productoId, tiendaId: tiendaId};
    return this.http.post<ModelModel>(this.urlBase + `/last_prediction`, model);
  }

}
