import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Observable} from "rxjs";
import {ResultadoValidacionModel} from "@app/shared/model/resultado-validacion.model";
import {HourlyWeatherDataModel} from "@app/shared/model/hourly-weather-data.model";

@Injectable()
export class HourlyWeatherDataService {

  urlBase = environment.api.base_url + environment.api.modules.hourly_weather_data;
  constructor(private http: HttpClient) { }

  getAllPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<HourlyWeatherDataModel>>(this.urlBase + '/all', searchRequest);
  }

  export(searchRequest: SearchRequest, formato?: string): Observable<unknown> {
    if (formato) {
      return this.http.post(this.urlBase + '/export/' + formato, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    } else {
      return this.http.post(this.urlBase + '/export', searchRequest, { observe: 'response', responseType: 'blob' });
    }
  }

  import(file: unknown, formato?: string): Observable<ResultadoValidacionModel> {
    if (formato) {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import/' + formato, file);
    } else {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import', file);
    }
  }
}
