import { Component, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { KeyValue } from '@angular/common';

export interface IStep {
  title: string;
  canGoBack: boolean;
  canBeClicked?: boolean;
  click?: () => void;
  condition?: () => boolean;
  disabled: boolean;
  previous: number;
  next: number;
  active?: boolean;
  nextStepText?: string;
  previousStepText?: string;
  nextEnabled?: () => boolean;
  previousEnabled?: () => boolean;
}

export interface ISteps {
  [id: string]: IStep;
}

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styles: []
})
export class StepperComponent implements OnInit {

  @HostBinding('class') class = 'flex flex-col h-full w-full';

  @Input() activeStep: string;
  @Input() steps: ISteps = {};
  stepsKeys: string[];
  @Input() showFooter = true;
  @Input() showHeader = true;
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;
  @Input() nextStepText = 'Siguiente';
  @Input() previousStepText = 'Anterior';

  @Output() nextStep = new EventEmitter<{ from: string, to: string }>();
  @Output() previousStep = new EventEmitter<{ from: string, to: string }>();
  @Output() lastStep = new EventEmitter();

  @Input() contentTemplate: TemplateRef<any>;

  previousEnabled = true;
  nextEnabled = true;

  constructor() {
  }

  originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return 0;
  };

  ngOnInit() {
    this.stepsKeys = [];
    for (const key in this.steps) {
      this.stepsKeys.push(key);
    }
  }

  isLastStep() {
    return this.activeStep === this.availableSteps[this.stepsKeys.length - 1];
  }


  isFirstStep() {
    return this.activeStep === this.availableSteps[0];
  }

  getNextStep() {
    return this.availableSteps[this.availableSteps.indexOf(this.activeStep) + 1];
  }

  calculateEnabledButtons() {
    if (this.isFirstStep()) {
      this.previousEnabled = false;
    }

    if (this.isLastStep()) {
      this.nextEnabled = false;
    }
  }

  private get availableSteps() {
    return Object.entries(this.steps).filter((k, i) => {
      return !k[1].disabled;
    }).map((value, index) => {
      return value[0];
    });
  }

  getPreviousStep() {
    return this.availableSteps[this.availableSteps.indexOf(this.activeStep) - 1];
  }

  onNextStep() {
    if (this.isLastStep()) {
      this.lastStep.emit(true);
    } else {
      const previousStep = JSON.parse(JSON.stringify(this.activeStep));

      this.activeStep = this.getNextStep();
      this.nextStep.emit({ from: previousStep, to: this.activeStep });
    }

    this.calculateEnabledButtons();
  }

  hideStep(step: string) {
    this.steps[step].disabled = true;
  }

  onPreviousStep() {
    if (!this.isFirstStep()) {
      const previous = this.activeStep;

      this.activeStep = this.getPreviousStep();
      this.previousStep.emit({ from: previous, to: this.activeStep });
    }

    this.calculateEnabledButtons();
  }

}
