import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {ModelTypeModel} from "@app/shared/model/model-type.model";

@Injectable()
export class TypeModelService {

  urlBase = environment.api.base_url + environment.api.modules.type_model;
  constructor(private http: HttpClient) { }

  getAllPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<ModelTypeModel>>(this.urlBase + '/page_all', searchRequest);
  }

  getAll() {
    return this.http.get<ModelTypeModel[]>(this.urlBase + '/all');
  }

}
