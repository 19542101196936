import {UsuarioModel} from "@app/shared/model/usuario.model";
import {AccountTypeModel} from "@app/shared/model/account-type.model";

export class CuentaModel {

  id: number;
  nombre: string;
  usuarios: UsuarioModel[];
  usuarioIds: number[];
  tipoCuentaId: number;
  tipoCuenta: AccountTypeModel;
  energizaToken: string;

}
