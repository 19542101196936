import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {CalendarUseTypeDayHourModel} from "@app/shared/model/calendar-use-type-day-hour.model";
import {Observable} from "rxjs";

@Injectable()
export class CalendarUseTypeDayHourService {

  urlBase = environment.api.base_url + environment.api.modules.calendar_use_type_day_hour;

  constructor(private http: HttpClient) {
  }

  save(horas :CalendarUseTypeDayHourModel[]): Observable<boolean> {
    return this.http.post<boolean>(this.urlBase + '/save', horas);
  }

  allByTipoDia(tipoDiaId : number): Observable<CalendarUseTypeDayHourModel[]> {
    return this.http.get<CalendarUseTypeDayHourModel[]>(this.urlBase + '/' + tipoDiaId +'/all_by_tipo_dia');
  }

}
