import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import {getMonthsBetweenDates, getYearsBetweenDates} from "@app/shared/dateutils";

export interface DateSelection {
  type: 'default' | 'monthyear';
  data: {
    years?: number[];
    months?: number[];
    monthyears?: string[];
  };
}

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styles: []
})
export class DateSelectorComponent implements OnInit, OnChanges {

  private selection = {};
  @ViewChild('containerYears') containerYears;
  @ViewChild('containerMonths') containerMonths;
  @ViewChild('containerYearMonths') containerYearMonths;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() showYears = true;
  @Input() showMonths = true;
  @Input() showDays = false;

  @Output() change = new EventEmitter();

  datefiltertype: 'default' | 'monthyear' = 'default'; // 0 normal, 1 monthyear
  selectedYears = [];
  selectedMonths = [];
  selectedMonthYears = [];
  years = [];
  months = [];
  monthyears = [];

  ngOnInit(): void {
    this.updateYearsAndMonths();

    this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate?.currentValue) {
      this.showMonths = false;
      setTimeout(() => {
        this.startDate = changes.startDate.currentValue;
        this.containerYears?.clearSelection();
        this.containerMonths?.clearSelection();
        this.containerYearMonths?.clearSelection();

        this.updateYearsAndMonths();
        this.showMonths = true;
      }, 10);
    }
  }

  updateYearsAndMonths(): void {
    if (this.startDate && this.endDate) {
      this.years = getYearsBetweenDates(this.startDate, this.endDate).map((d) => moment(d).year());
      this.monthyears = getMonthsBetweenDates(this.startDate, this.endDate);
    } else {
      this.years = getYearsBetweenDates(moment(new Date()).subtract(1, 'years').startOf('year').toDate(), new Date()).map((d) => moment(d).year());
      this.monthyears = getMonthsBetweenDates(moment(new Date()).startOf('year').toDate(), new Date());
    }
  }

  onChange(): void {
    if (this.selectedMonthYears || this.selectedYears || this.selectedYears) {
      this.selection = {
        type: this.datefiltertype,
        data:
          this.datefiltertype === 'default'
            ? {
              years: this.selectedYears,
              months: this.selectedMonths,
            }
            : { monthyears: this.selectedMonthYears }
      };
      this.change.emit(this.selection);
    } else {
      this.change.emit(undefined);
    }
  }

}
