import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AccountTypeModel} from "@app/shared/model/account-type.model";

@Injectable()
export class AccountTypeService {

  urlBase = environment.api.base_url + environment.api.modules.type_account;
  constructor(private http: HttpClient) { }

  getAll(): Observable<AccountTypeModel[]> {
    return this.http.get<AccountTypeModel[]>(this.urlBase + '/all');
  }

  get(tipoCuentaId: number): Observable<AccountTypeModel> {
    return this.http.get<AccountTypeModel>(this.urlBase + '/' + tipoCuentaId + '/one');
  }

}
