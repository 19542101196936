import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {HoraModel} from "@app/shared/model/calendar-use-type-day-hour.model";

@Component({
  selector: 'app-calendar-hours-use',
  templateUrl: './calendar-hours-use.component.html'
})
export class CalendarHoursUseComponent implements OnInit {

  @Input() horas: HoraModel[] = [];
  @Output() updateHoras = new EventEmitter<HoraModel[]>();

  constructor() {
    if (this.horas.length === 0) {
      for (let i = 0; i < 24; i++) {
        this.horas.push({hora: i, activo: false});
      }
    }
  }

  ngOnInit() {}

  toggleValue(index) {
    this.horas[index].activo = !this.horas[index].activo;
    this.updateHoras.emit(this.horas);
  }
}
