import {HttpClientModule} from '@angular/common/http';
import {Injectable, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AzComponentsModule, AzNotifierService} from '@azigrene/components';
import {AzDataManagerModule} from '@azigrene/data-manager';
import {PIPES_SETTINGS_LOADER, PipesLocaleSettings, PipesLocaleSettingsLoader} from '@azigrene/pipes';
import {TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslocoHttpLoader} from './transloco.loader';
import {CommonModule, registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeES from '@angular/common/locales/es';
import {AuthModule} from '@app/auth/auth.module';
import {CoreModule} from '@app/core/core.module';
import {StoreModule} from '@app/store/store.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {CambioCuentaComponent} from '@app/cuenta/components/cambio-cuenta/cambio-cuenta.component';
import {NZ_DATE_CONFIG} from 'ng-zorro-antd/i18n';
import moment from 'moment';
import {Store} from '@ngxs/store';
import {MainState} from '@app/core/store/main.store';

registerLocaleData(localeES);


export const defaultAccountSettings: PipesLocaleSettings = {
  locale: 'es',
  dateFormat: 'DD/MM/YYYY',
  currencyCode: '€',
  hourFormat: 'HH:mm',
  timezone: 'Europe/Madrid'
};

@Injectable({ providedIn: "root" })
export class PipesSettingsLoader implements PipesLocaleSettingsLoader {

  // Aquí se puede obtener del store de forma que el usuario puede tener sus settings en caché. Por defecto se utiliza un objeto básico.
  constructor(private store: Store) { }

  getSettings(): Observable<PipesLocaleSettings> {
    return this.store.select(MainState.accountSettings);
    //return of(defaultAccountSettings);
  }

}


@NgModule({
  declarations: [AppComponent, CambioCuentaComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxChartsModule,
    HttpClientModule,
    AppRoutingModule,
    AzComponentsModule,
    AuthModule,
    CoreModule,
    StoreModule,
    AzDataManagerModule.forRoot({
      tableSaveStatus: true,
      tableStorageKey: 'previsionVentas-datamanager',
    }),
    AngularSvgIconModule.forRoot(),
    TranslocoModule,
  ],
  providers: [
    {
      provide: PIPES_SETTINGS_LOADER,
      useClass: PipesSettingsLoader,
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader,
    },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: moment.localeData(navigator.language).firstDayOfWeek(),
      },
    },
    AzNotifierService,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'es'],
        defaultLang: 'es',
        fallbackLang: 'es',
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslocoService, private store: Store, private injector: Injector) {
    this.translate.setDefaultLang(defaultAccountSettings.locale || 'es');
    this.translate.setActiveLang(this.store.selectSnapshot(MainState.accountSettings)?.locale || defaultAccountSettings.locale);
    translate.getTranslation();
  }
}
