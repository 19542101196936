import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Injectable} from "@angular/core";
import {PredictionProcessProductModel} from "@app/shared/model/prediction-process-product.model";
import {Observable} from "rxjs";

@Injectable()
export class PredictionProcessProductService {

  urlBase = environment.api.base_url + environment.api.modules.process_prediction_product;
  constructor(private http: HttpClient) { }

  getAllPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<PredictionProcessProductModel>>(this.urlBase + '/all', searchRequest);
  }

  export(searchRequest: SearchRequest): Observable<unknown> {
    return this.http.post(this.urlBase + '/export', searchRequest, { observe: 'response', responseType: 'blob' });
  }

  get(processId: number): Observable<PredictionProcessProductModel> {
    return this.http.get<PredictionProcessProductModel>(this.urlBase + '/' + processId + '/one');
  }

  lastPrediction(productoId: number, tiendaId: number): Observable<PredictionProcessProductModel> {
    const predictionProcessProduct= {productoId: productoId, tiendaId: tiendaId};
    return this.http.post<PredictionProcessProductModel>(this.urlBase + `/last_prediction`, predictionProcessProduct);
  }

}
