import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import {Observable} from "rxjs";
import {ModelTypeModel} from "@app/shared/model/model-type.model";


@Injectable()
export class EnumsService {

  constructor(private http: HttpClient) {
  }

  urlBase = environment.api.base_url;

  getModelType(): Observable<ModelTypeModel[]> {
    return this.http.get<ModelTypeModel[]>(this.urlBase + '/tipo_modelo/all');
  }

}
