import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CambioCuentaComponent} from "@app/cuenta/components/cambio-cuenta/cambio-cuenta.component";
import {AdminGuard} from "@app/core/guards/admin.guard";
import {NewPasswordComponent} from "@app/auth/pages/new-password/new-password.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: 'cambio-cuenta',
    component: CambioCuentaComponent
  },
  {
    path: 'recuperar-password/:token',
    component: NewPasswordComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user-panel/user-panel.module').then(m => m.UserPanelModule)
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () => import('./modules/back-office/back-office.module').then(m => m.BackOfficeModule)
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./cuenta/cuenta.module').then(m => m.CuentaModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
