import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Observable} from "rxjs";
import {ProductFieldModel} from "@app/shared/model/product-field.model";


@Injectable()
export class ProductFieldService {

  constructor(private http: HttpClient) {
  }

  urlBase = environment.api.base_url + environment.api.modules.product_field;

  getAllPaginated(searchRequest: SearchRequest): Observable<PaginatedResponse<ProductFieldModel>> {
    return this.http.post<PaginatedResponse<ProductFieldModel>>(this.urlBase + '/all', searchRequest);
  }

  getAll(): Observable<ProductFieldModel[]> {
    return this.http.get<ProductFieldModel[]>(this.urlBase + '/all');
  }

  get(productoCampoId: number): Observable<ProductFieldModel> {
    return this.http.get<ProductFieldModel>(this.urlBase + '/' + productoCampoId + '/one');
  }

  update(productField: ProductFieldModel): Observable<ProductFieldModel> {
    return this.http.put<ProductFieldModel>(this.urlBase + '/' + productField.id + '/update', productField);
  }

  create(productField: ProductFieldModel): Observable<ProductFieldModel> {
    return this.http.post<ProductFieldModel>(this.urlBase + '/create', productField);
  }

  delete(productoCampoId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + productoCampoId + '/delete');
  }

}
