import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "@env/environment";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Observable} from "rxjs";
import {CuentaModel} from "@app/shared/model/cuenta.model";
import {take} from "rxjs/operators";

@Injectable()
export class AccountService {

  urlBase = environment.api.base_url + environment.api.modules.account;

  constructor(private http: HttpClient) {
  }

  getAllPaginated(searchRequest: SearchRequest): Observable<PaginatedResponse<CuentaModel>> {
    return this.http.post<PaginatedResponse<CuentaModel>>(this.urlBase + '/all', searchRequest);
  }

  public one(): Observable<CuentaModel> {
    return this.http.get<CuentaModel>(`${this.urlBase}/one`);
  }

  get(cuentaId: number): Observable<CuentaModel> {
    return this.http.get<CuentaModel>(this.urlBase + '/' + cuentaId + '/one');
  }

  getByUser(usuarioId: number): Observable<CuentaModel[]> {
    return this.http.get<CuentaModel[]>(this.urlBase + '/' + usuarioId + '/by_user');
  }

  create(cuenta: CuentaModel, usuarioIds: number[], usuarioSearchRequest: SearchRequest): Observable<CuentaModel> {
    return this.http.post<CuentaModel>(this.urlBase + '/create', {...cuenta, usuarioIds: usuarioIds, usuarioSearchRequest: usuarioSearchRequest});
  }

  update(cuenta: CuentaModel, usuarioIds: number[], usuarioSearchRequest: SearchRequest) {
    return this.http.put<CuentaModel>(`${this.urlBase}/update`, {...cuenta, usuarioIds: usuarioIds, usuarioSearchRequest: usuarioSearchRequest});
  }

  delete(cuentaId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + cuentaId + '/delete');
  }

  getToken(cuentaId: number):Observable<string> {
    return this.http.get(this.urlBase + '/' + cuentaId + '/get_token', {responseType: 'text'});
  }

  updateToken(cuentaId: number, token: string): Observable<CuentaModel>  {
    return this.http.put<CuentaModel>(`${this.urlBase}/update_token`, {id: cuentaId, energizaToken: token});
  }

}
