import {CalendarUseModel} from "@app/shared/model/calendar-use.model";
import {WeatherStationModel} from "@app/shared/model/weather-station.model";

export class StoreModel {

  id: number;
  cuentaId: number;
  nombre: string;
  calendarioUsosId: number;
  calendarioUsos: CalendarUseModel;
  estacionMeteorologicaId: number;
  estacionMeteorologica: WeatherStationModel;

}
