import moment from 'moment';
import * as datefns from 'date-fns';


export class DateUtils {

  static fns = datefns;
  static dates = {
    lastMonth: moment().add(-1, 'month').toDate(),
    lastYear: moment().add(-1, 'year').toDate(),
    lastWeek: moment().add(-1, 'week').toDate(),
    lastDay: moment().add(-1, 'day').toDate(),
    lastMonthPeriod: [moment().add(-1, 'month').startOf('month').toDate(), moment().add(-1, 'month').endOf('month').toDate()],
    lastYearPeriod: [moment().add(-1, 'year').startOf('year').toDate(), moment().add(-1, 'year').endOf('year').toDate()],
    lastWeekPeriod: [moment().add(-1, 'week').startOf('week').toDate(), moment().add(-1, 'week').endOf('week').toDate()],
    thisMonthPeriod: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    thisYearPeriod: [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
    thisWeekPeriod: [moment().startOf('week').toDate(), moment().endOf('week').toDate()]
  };

  static json = {
    lastMonth: moment().add(-1, 'month').toJSON(),
    lastYear: moment().add(-1, 'year').toJSON(),
    lastWeek: moment().add(-1, 'week').toJSON(),
    lastDay: moment().add(-1, 'day').toJSON(),
    lastMonthPeriod: [moment().add(-1, 'month').startOf('month').toJSON(), moment().add(-1, 'month').endOf('month').toJSON()],
    lastYearPeriod: [moment().add(-1, 'year').startOf('year').toJSON(), moment().add(-1, 'year').endOf('year').toJSON()],
    lastWeekPeriod: [moment().add(-1, 'week').startOf('week').toJSON(), moment().add(-1, 'week').endOf('week').toJSON()],
    thisMonthPeriod: [moment().startOf('month').toJSON(), moment().endOf('month').toJSON()],
    thisYearPeriod: [moment().startOf('year').toJSON(), moment().endOf('year').toJSON()],
    thisWeekPeriod: [moment().startOf('week').toJSON(), moment().endOf('week').toJSON()]
  };

}

export class DateFilter {

  startDate: Date;
  endDate: Date;
  type: number;

  constructor(startDate, endDate, type) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.type = type;
  }

}

export const DateFilterTypes = {
  WEEK: 0,
  DAY: 1,
  MONTH: 2,
  YEAR: 3
};

export function generateDateFilters() {
  const daysofweek: DateFilter[] = [];
  let now = new Date();
  const startOfWeek = moment()
    .startOf('week')
    .toDate();

  getDatesBetweenDates(startOfWeek, now).forEach(element => {
    daysofweek.push(
      new DateFilter(
        moment(element)
          .startOf('day')
          .toDate(),
        moment(element)
          .endOf('day')
          .toDate(),
        DateFilterTypes.DAY
      )
    );
  });
  const weeksofmonth: DateFilter[] = [];

  now = new Date();
  const startOfMonth = moment()
    .add(-4, 'week')
    .startOf('week')
    .toDate();

  getWeeksBetweenDates(startOfMonth, now).forEach(element => {
    weeksofmonth.push(
      new DateFilter(
        moment(element)
          .startOf('week')
          .toDate(),
        moment(element)
          .endOf('week')
          .toDate(),
        DateFilterTypes.WEEK
      )
    );
  });
  const monthsofyear: DateFilter[] = [];

  now = new Date();
  const startOfYear = moment()
    .startOf('year')
    .toDate();

  getMonthsBetweenDates(startOfYear, now).forEach(element => {
    monthsofyear.push(
      new DateFilter(
        moment(element)
          .startOf('month')
          .toDate(),
        moment(element)
          .endOf('month')
          .toDate(),
        DateFilterTypes.MONTH
      )
    );
  });
  const years: DateFilter[] = [];

  now = new Date();
  const start = moment()
    .add(-2, 'year')
    .toDate();

  getYearsBetweenDates(start, now).forEach(element => {
    years.push(
      new DateFilter(
        moment(element)
          .startOf('year')
          .toDate(),
        moment(element)
          .endOf('year')
          .toDate(),
        DateFilterTypes.YEAR
      )
    );
  });

  return {
    days: daysofweek,
    weeks: weeksofmonth,
    months: monthsofyear,
    years: years
  };
}


export function compare(a, b) {
  // eslint-disable-next-line eqeqeq
  return a === b;
}

export function formatDate(date: Date) {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export function getDatesBetweenDates(d1: Date, d2: Date) {
  const dates = [];

  while (d1 <= d2) {
    dates.push(new Date(d1));
    d1 = moment(d1)
      .add(1, 'days')
      .toDate();
  }

  return dates;
}

export function getMonthsBetweenDates(d1: Date, d2: Date) {
  const dates = [];

  while (d1 <= d2) {
    dates.push(new Date(d1));
    d1 = moment(d1)
      .add(1, 'month')
      .toDate();
  }

  return dates;
}

export function getWeeksBetweenDates(d1: Date, d2: Date) {
  const dates = [];

  while (d1 <= d2) {
    dates.push(new Date(d1));
    d1 = moment(d1)
      .add(1, 'week')
      .toDate();
  }

  return dates;
}

export function getYearsBetweenDates(d1: Date, d2: Date) {
  const dates = [];

  while (d1 <= d2) {
    dates.push(new Date(d1));
    d1 = moment(d1)
      .add(1, 'year')
      .toDate();
  }

  return dates;
}
