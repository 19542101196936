import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CalendarUseDayModel} from "@app/shared/model/calendar-use-day.model";
import {SearchRequest} from "@azigrene/searchrequest";

@Injectable()
export class UsageCalendarService {

  urlBase = environment.api.base_url + environment.api.modules.calendar_use_day;

  constructor(private http: HttpClient) {
  }

  allByYear(calendarUseId: number, year: number): Observable<CalendarUseDayModel[]> {
    return this.http.get<CalendarUseDayModel[]>(this.urlBase + '/' + calendarUseId + '/all_by_year/'+ year);
  }

  save(days:CalendarUseDayModel[],calendarUseId:number, year: number): Observable<number> {
    return this.http.post<number>(this.urlBase + '/' + calendarUseId + '/save/'+ year, days);
  }

}
