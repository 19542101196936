import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiTokenModel} from "@app/shared/model/api-token.model";

@Injectable()
export class ApiTokenService {

    urlBase = environment.api.base_url + environment.api.modules.integracion_token;

    constructor(private http: HttpClient) {
    }

    public one(): Observable<ApiTokenModel> {
        return this.http.get<ApiTokenModel>(this.urlBase + '/one');
    }

    public create(fechaValida: boolean): Observable<ApiTokenModel> {
        return this.http.post<ApiTokenModel>(this.urlBase + '/create', fechaValida);
    }

    public reload(id: number, fechaValida: boolean): Observable<ApiTokenModel> {
        return this.http.put<ApiTokenModel>(this.urlBase + '/' + id + '/reload', fechaValida);
    }

}
