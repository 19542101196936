import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {WeatherStationModel} from "@app/shared/model/weather-station.model";
import {Observable} from "rxjs";
import {ResultadoValidacionModel} from "@app/shared/model/resultado-validacion.model";

@Injectable()
export class WeatherStationService {

  urlBase = environment.api.base_url + environment.api.modules.weather_station;
  constructor(private http: HttpClient) { }

  getAllPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<WeatherStationModel>>(this.urlBase + '/all', searchRequest);
  }

  get(weatherStationId: number): Observable<WeatherStationModel> {
    return this.http.get<WeatherStationModel>(this.urlBase + '/' + weatherStationId + '/one');
  }

  create(weatherStation: WeatherStationModel): Observable<WeatherStationModel> {
    return this.http.post<WeatherStationModel>(this.urlBase + '/create', weatherStation);
  }

  update(weatherStation: WeatherStationModel):  Observable<WeatherStationModel> {
    return this.http.put<WeatherStationModel>(this.urlBase + '/' + weatherStation.id + '/update', weatherStation);
  }

  delete(weatherStationId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + weatherStationId + '/delete');
  }

  export(searchRequest: SearchRequest, formato?: string): Observable<unknown> {
    if (formato) {
      return this.http.post(this.urlBase + '/export/' + formato, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    } else {
      return this.http.post(this.urlBase + '/export', searchRequest, { observe: 'response', responseType: 'blob' });
    }
  }

  import(file: unknown, formato?: string): Observable<ResultadoValidacionModel> {
    if (formato) {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import/' + formato, file);
    } else {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import', file);
    }
  }
}
