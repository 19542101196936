import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Observable} from "rxjs";
import {CalendarUseModel} from "@app/shared/model/calendar-use.model";

@Injectable()
export class CalendarUseService {

  urlBase = environment.api.base_url + environment.api.modules.calendar_use;

  constructor(private http: HttpClient) {
  }

  getAllPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<CalendarUseModel>>(this.urlBase + '/all', searchRequest);
  }

  get(calendarUseId: number): Observable<CalendarUseModel> {
    return this.http.get<CalendarUseModel>(this.urlBase + '/' + calendarUseId + '/one');
  }

  create(calendarUseModel: CalendarUseModel): Observable<CalendarUseModel> {
    return this.http.post<CalendarUseModel>(this.urlBase + '/create', calendarUseModel);
  }

  update(calendarUseModel: CalendarUseModel): Observable<CalendarUseModel> {
    return this.http.put<CalendarUseModel>(this.urlBase + '/' + calendarUseModel.id + '/update', calendarUseModel);
  }

  delete(calendarUseId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + calendarUseId + '/delete');
  }

  prepareDelete(calendarUseId: number): Observable<number> {
    return this.http.get<number>(this.urlBase + '/' + calendarUseId + '/prepare_delete');
  }

  checkCalendar(calendarUseModel: CalendarUseModel): Observable<boolean> {
    return this.http.post<boolean>(this.urlBase + '/check_calendario_uso', calendarUseModel);
  }

}
