import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {StoreModel} from "@app/shared/model/store.model";
import {Observable} from "rxjs";

@Injectable()
export class StoreService {

  constructor(private http: HttpClient) {
  }

  urlBase = environment.api.base_url + environment.api.modules.tienda;

  getAllPaginated(searchRequest: SearchRequest): Observable<PaginatedResponse<StoreModel>> {
    return this.http.post<PaginatedResponse<StoreModel>>(this.urlBase + '/all', searchRequest);
  }

  getAll(): Observable<StoreModel[]> {
    return this.http.get<StoreModel[]>(this.urlBase  + '/all_list');
  }

  getAllByProduct(productoId: number): Observable<StoreModel[]> {
    return this.http.get<StoreModel[]>(this.urlBase + '/' + productoId + '/all_by_product');
  }

  get(tiendaId: number): Observable<StoreModel> {
    return this.http.get<StoreModel>(this.urlBase + '/' + tiendaId + '/one');
  }

  update(store: StoreModel): Observable<StoreModel> {
    return this.http.put<StoreModel>(this.urlBase + '/' + store.id + '/update', store);
  }

  create(store: StoreModel): Observable<StoreModel> {
    return this.http.post<StoreModel>(this.urlBase + '/create', store);
  }

  delete(tiendaId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + tiendaId + '/delete');
  }

}
