import {Component, HostBinding, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@app/auth/services/auth.service";
import {Actions, ofActionSuccessful, Store} from "@ngxs/store";
import {ActivatedRoute, Router} from "@angular/router";
import {CreateUserSuccessful, LoginSuccessful} from "@app/core/store/auth.store";
import {FillCuenta, FillLogin, FillUsuario, MainState} from "@app/core/store/main.store";
import {take} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";
import {LoginResponse} from "@app/auth/model/login";
import {Subscription} from "rxjs";
import {CuentaModel} from "@app/shared/model/cuenta.model";
import {RecoverPasswordService} from "@app/shared/service/recover-password.service";
import {UserService} from "@app/shared/service/user.service";
import {AccountService} from "@app/shared/service/account.service";

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnDestroy{
  @HostBinding('class') class = "block h-screen bg-gray-50";
  form: FormGroup;
  formRecuperar: FormGroup;
  recuperado = false;
  errores: string[] = [];
  loginIn: boolean = false;

  fase: 'login' | 'recuperar' | 'login-sso' = 'login';

  loginSuccessful$: Subscription;
  userSuccessful$: Subscription;

  loadingRecuperacion = false;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslocoService,
    private recoverPasswordService: RecoverPasswordService,
    private userService: UserService,
    private authService: AuthService,
    private accountService: AccountService,
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, Validators.required]
    });
    this.formRecuperar = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]]
    });

    this.userSuccessful$ = this.actions$.pipe(ofActionSuccessful(CreateUserSuccessful)).subscribe(action => {
      action.user.id = action.user.usuarioId;
      this.store.dispatch(new FillUsuario(action.user));
    });

    this.loginSuccessful$ = this.actions$.pipe(ofActionSuccessful(LoginSuccessful)).subscribe(action => {
      const cuenta = this.store.selectSnapshot(MainState.cuenta);
      const find = action.login.cuentas.find((c)=>c.id == cuenta?.id);

      this.store.dispatch(new FillLogin(action.login));
      if(find && cuenta && cuenta.tipoCuentaId) {
        this.loginCuenta(cuenta)
      } else if(action.login.cuentas.length>0) {
        this.fetchCuenta(action.login.cuentas[0]);
      } else {
      }
    });

  }

  logIn(): void {
    this.loginIn = true;
    this.errores = [];

    this.authService.auth(this.form.value).pipe(take(1)).subscribe((login: LoginResponse) => {
      const loginResponse:LoginResponse = login;

      if(loginResponse.cuentas?.length>0) {
        this.store.dispatch(new CreateUserSuccessful(loginResponse));
        this.store.dispatch(new LoginSuccessful(loginResponse));
        this.loginIn = false;
      } else {
        this.errores.push(this.translateService.translate('login.error.cuentas'));
        this.loginIn = false;
      }
    }, err => {
      this.errores.push(this.translateService.translate('login.error.email-pass'));
      this.loginIn = false;
    });
  }

  fetchCuenta(cuentaSlim: CuentaModel): void {
    const params = this.activatedRoute.snapshot.queryParams;

    this.accountService.get(cuentaSlim.id).pipe(take(1)).subscribe((cuenta: CuentaModel) => {
      this.loginCuenta(cuenta);
    });
  }

  loginCuenta(cuenta: CuentaModel): void {
    const params = this.activatedRoute.snapshot.queryParams;

    this.store.dispatch(new FillCuenta(cuenta));
    this.router.navigate(['/main'], {queryParams: params});
  }

  recuperarPassword(): void {
    this.loadingRecuperacion = true;
    this.errores = [];
    this.recoverPasswordService
        .recuperarPassword(this.formRecuperar.value.email)
        .pipe(take(1))
        .subscribe(
            (ok) => {
              if (ok) {
                this.formRecuperar.reset();
                this.recuperado = true;
                this.loadingRecuperacion = false;
              }
            },
            (err) => {
              this.errores.push(err.error.message);
              this.loadingRecuperacion = false;
            }
        );
  }

  ngOnDestroy(): void {
    this.loginSuccessful$.unsubscribe();
    this.userSuccessful$.unsubscribe();
  }

}
