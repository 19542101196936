import {CuentaModel} from "@app/shared/model/cuenta.model";
import {SesionModel} from "@app/shared/model/sesion.model";

export enum AvailableThemes {
  'default-theme' = 'default-theme',
  'red-theme' = 'red-theme',
  'orange-theme' = 'orange-theme',
  'yellow-theme' = 'yellow-theme',
  'blue-theme' = 'blue-theme',
  'green-theme' = 'green-theme',
  'indigo-theme' = 'indigo-theme',
  'clear-red-theme' = 'clear-red-theme',
  'clear-orange-theme' = 'clear-orange-theme',
  'clear-yellow-theme' = 'clear-yellow-theme',
  'clear-blue-theme' = 'clear-blue-theme',
  'clear-green-theme' = 'clear-green-theme',
  'clear-indigo-theme' = 'clear-indigo-theme',
}


export class UsuarioModel {
  id: number;
  nombre: string;
  rol: 'USER' | 'ADMIN';
  email: string;
  theme: AvailableThemes;
  cuentas: Array<CuentaModel>;
}
