import {SearchInputs, SearchRequest, SearchRequestOptions} from '@azigrene/searchrequest';
import {isAfter, isBefore, isSameDay} from "date-fns";
import {AbstractControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {DateSelection} from "@app/shared/components/date-selector/date-selector.component";
import moment from "moment";
import {DataFilterDeclaration, DataFilterTypes} from "@azigrene/data-manager";
import {ProductFieldModel} from "@app/shared/model/product-field.model";
import {MainState} from "@app/core/store/main.store";
import {Store} from "@ngxs/store";


export function mergeSearchRequests(base: SearchRequest, ...s1: SearchRequest[]): SearchRequest {

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchRequest = new SearchRequest(null, { paginate: true, pageSize: base.pagination.pageSize || 50 });

  searchRequest.setFilterStatus(base.filterStatus, false);
  searchRequest.setSortStatus(base.sortStatus, false);
  searchRequest.setPage(base.pagination.page, false);
  searchRequest.inputsquery.search.value = base.inputsquery.search.value;
  searchRequest.inputsquery.search.ids = base.inputsquery.search.ids;
  searchRequest.toRequest();
  s1.forEach((sr) => {
    if (!sr) {
      return;
    }

    const _s = cloneObject(searchRequest.filterStatus);

    searchRequest.mergeFilters(sr, _s, false);
  });

  return searchRequest;
}

export function cloneObject(obj: {[id: string]: any}): any {
  return JSON.parse(JSON.stringify(obj));
}

export function searchRequestFromJSON(req: { inputsquery: SearchInputs } | SearchRequest, options?: SearchRequestOptions): SearchRequest {
  const sr: SearchRequest = new SearchRequest(null, options);

  req.inputsquery.filters.forEach((f) => {
    sr.addFilter(f.id, f.value, false);
  });
  req.inputsquery.sorts.forEach((f) => {
    sr.addSort(f.id, f.value, false);
  });

  return sr;
}

/**
 * Elimina elmentos repetidos de un listado. Ambos elementos son eliminados del array.
 *
 * @param selecionados
 */
export function removeDuplicatesList(selecionados: any[]): any[] {
  selecionados = selecionados.sort((a,b) => a.id - b.id);
  for(let i=0; i<selecionados.length-1; i++) {
    if(selecionados[i].id == selecionados[i+1].id) {
      selecionados.splice(i,2);
      i--;
    }
  }

  return selecionados;
}

export const FormUtils = {
  validators: {
    atLeastOneRequired: (field1: string, field2: string): ValidatorFn => (group: FormGroup) => {
      if (group) {
        if (group.controls[field1].value || group.controls[field2].value) {
          return null;
        }
      }

      return { 'atleast-one-required': [field1, field2] };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    conditionTrue: (condition: boolean): ValidatorFn => (control: AbstractControl) => {
      if (condition) {
        return null;
      }

      return { 'condition-not-true': true };
    },
    requireLengthOf: (length: number): ValidatorFn => (control: AbstractControl) => {
      if (control.value && control.value.length && control.value.length == length) {
        return null;
      }

      return { 'required-length': length };
    },
    indexesNotNull: (...indexes: number[]): ValidatorFn => (control: AbstractControl) => {
      if (control.value && control.value.length && control.value.filter((v, i) => indexes.includes(i) && v == undefined && v == null).length == 0) {
        return null;
      }

      return { 'indexes-null': true };
    },
    allValuesNotNull: (control: AbstractControl): any => {
      if (control.value && control.value.length && control.value.filter((v) => v == undefined && v == null).length == 0) {
        return null;
      }

      return { 'all-values-not-null': true };
    },
    onlyNumbers: (control: AbstractControl): any  => {
      const value: string = control.value || '';
      const valid = value.match(/^[0-9]*$/);


      return valid ? null : { 'only-numbers': true };
    },
    checkPassword(control: AbstractControl): {'password-invalid': boolean} {
      const value: string = control.value || '';
      const valid = value.match(/^(?=.*[A-Za-zñÑ])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])[A-Za-zñÑ\d!"#$%&'()*+,-./:;<=>?@^_`{|}~]{8,}$/);
      return valid ? null : {'password-invalid': true};
    },
    checkMail(control: AbstractControl): any  {
      const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/; // fragment locator


      return control.value && control.value.length > 2 && !pattern.test(control.value) ?  {'mail-invalid': true } : null;
    }
  },
  functions: {}
};

export function updateFiltersDateSelection(searchRequest: SearchRequest, dateSelection: DateSelection, yearFilterId: string, monthFilterId: string, monthYearFilterId: string): void {
  searchRequest.removeFilter(yearFilterId, false);
  searchRequest.removeFilter(monthFilterId, false);
  if (dateSelection.type === 'default') {
    searchRequest.removeFilter(monthYearFilterId, false);
    if (dateSelection.data.years.length) {
      searchRequest.addFilter(yearFilterId, dateSelection.data.years, false);
    }

    if (dateSelection.data.months.length) {
      searchRequest.addFilter(monthFilterId, dateSelection.data.months, false);
    }
  } else {
    if (dateSelection.data.monthyears.length) {
      searchRequest.addFilter(
        monthYearFilterId,
        dateSelection.data.monthyears.map((d) => moment(d, 'MMM YY').format('MMYYYY')),
        false
      );
    }
  }
}

export function removeProductoFilters(base: SearchRequest, codigos:string[]): void {
  Object.keys(base.filterStatus).forEach((key) => {
    if (key.indexOf('p_') >= 0 || codigos.includes(key)) {
      base.removeFilter(key, false);
    }
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types
export function addDynamicFilters(filterDeclaration: {[id: string]: DataFilterDeclaration}, dynamicFilters: ProductFieldModel[]) {
  dynamicFilters.forEach((value) => {
    let add = true;
    let type = 0;

    if(value.tipo == "FECHA") {
      type = DataFilterTypes.Date;
    } else if(value.tipo == "TEXTO") {
      type = DataFilterTypes.AsyncMultiple;
    } else if(value.tipo == "BOOLEAN") {
      type = DataFilterTypes.Boolean;
    } else {
      add=false;
    }

    if(add) {
      filterDeclaration[value.codigo] = {
        id: value.codigo, label: value.descripcion, placeholder: value.codigo, type: type, alwaysVisible: false
      }
    }
  });

  return filterDeclaration;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function flattenTree(treeObj:object, idAttr = 'id', parentAttr = 'parentCode', childrenAttr = 'children', levelAttr = 'level'): any[] {
  function flattenChild(childObj, parentCode, level): any[] {
    let array = [];
    const childCopy = Object.assign({}, childObj);

    childCopy[levelAttr] = level;
    childCopy[parentAttr] = parentCode;
    delete childCopy[childrenAttr];
    array.push(childCopy);

    array = array.concat(processChildren(childObj, level));

    return array;
  }

  function processChildren(obj, level = 0): any[] {
    let array = [];

    obj[childrenAttr].forEach((childObj) => {
      array = array.concat(flattenChild(childObj, obj[idAttr], level + 1));
    });

    return array;
  }

  const result = processChildren(treeObj);

  return result;
}

export const FormValidatorGroups = {
  fechas: [Validators.required, FormUtils.validators.requireLengthOf(2), FormUtils.validators.indexesNotNull(0)],
  fechas_mandatory: [Validators.required, FormUtils.validators.requireLengthOf(2), FormUtils.validators.allValuesNotNull]
};

export const ChartUtils = {
  colors: ['#0284C7', '#34D399', '#FBBF24', '#F87171', '#94A3B8', '#22D3EE', '#818CF8', '#C084FC', '#FB7185']
};

export const DateUtils = {

  getListNameMonth: (store: Store): string[] => {
    const meses: string[] = [];
    const year = new Date().getFullYear()

    for(let i=0;i<12;i++) {
      const date:Date = new Date(year, i, 1);
      const monthFormat = store.selectSnapshot(MainState.accountSettings).nzMonthFormat;
      const locale = store.selectSnapshot(MainState.accountSettings).locale;

      meses.push(moment(date).locale(locale).format(monthFormat));
    }

    return meses;
  },

  finalDate: (date: Date): Date=> {
    const d = new Date(date);

    return DateUtils.withoutTimezone(DateUtils.setTimezoneHourZero(DateUtils.setTimezone(d)));
  },

  withoutTimezone: (date: Date): Date => {
    const d = new Date(date);
    const timeZoneDifference = (d.getTimezoneOffset() / 60) * -1; //convert to positive value.

    d.setTime(d.getTime() + timeZoneDifference * 60 * 60 * 1000);

    return d;
  },
  setTimezone: (date: Date):Date => {
    const d = new Date(date);
    const timeZoneDifference = (d.getTimezoneOffset() / 60) * -1; //convert to positive value.

    d.setTime(d.getTime() - timeZoneDifference * 60 * 60 * 1000);

    return d;
  },
  setTimezoneHourZero: (date: Date): Date => {
    const d = new Date(date);
    const hour = (d.getTimezoneOffset() / 60) * -1;

    d.setHours(hour,0,0,0);

    return d;
  },
  isBefore: (thisOne: Date, thatOne: Date): boolean => {
    return isBefore(thisOne, thatOne);
  },
  isAfter: (thisOne: Date, thatOne: Date): boolean => {
    return isAfter(thisOne, thatOne);
  },
  isBeforeOrSame: (thisOne: Date, thatOne: Date): boolean => {
    return isBefore(thisOne, thatOne) || isSameDay(thisOne, thatOne);
  },
  isAfterOrSame: (thisOne: Date, thatOne: Date): boolean => {
    return isAfter(thisOne, thatOne) || isSameDay(thisOne, thatOne);
  },

  isNextMonth:(thisOne: Date, thatOne: Date): boolean => {
    if(thisOne.getMonth() == 11) {
      return thatOne.getMonth() == 0 && thisOne.getFullYear() + 1 == thatOne.getFullYear();
    } else {
      return thisOne.getMonth() + 1 == thatOne.getMonth() && thisOne.getFullYear() == thatOne.getFullYear();
    }
  },

  isNextWeek(thisOne: Date, thatOne: Date): boolean {
    const fecha = new Date(thisOne);

    fecha.setDate(fecha.getDate() + 7);

    return this.getWeek(fecha) == this.getWeek(thatOne);
  },

  isSameMonth(thisOne: Date , thatOne: Date ): boolean {
    return thisOne.getMonth() == thatOne.getMonth() && thisOne.getFullYear() == thatOne.getFullYear();
  },

  getNextMonth:(thisOne: Date):Date => {
    if (thisOne.getMonth() == 11) {
      return new Date(thisOne.getFullYear() + 1, 0, 1);
    } else {
      return new Date(thisOne.getFullYear(), thisOne.getMonth() + 1, 1);
    }
  },

  getNextWeek:(thisOne: Date): Date => {
    const fecha = new Date(thisOne);

    fecha.setDate(thisOne.getDate() + 7);

    return fecha
  },

  isSameWeek(thisOne: Date , thatOne: Date ): boolean {
    return                     this.getWeek(thisOne) == this.getWeek(thatOne) &&  thisOne.getFullYear()      ==  thatOne.getFullYear() ||
      thisOne.getMonth() == 0  && thatOne.getMonth() == 11 && this.getWeek(thisOne) == this.getWeek(thatOne) && (thisOne.getFullYear() - 1) ==  thatOne.getFullYear() ||
      thisOne.getMonth() == 11 && thatOne.getMonth() == 0  && this.getWeek(thisOne) == this.getWeek(thatOne) &&  thisOne.getFullYear()      == (thatOne.getFullYear() - 1);
  },

  getPreviousYear(date: Date):Date {
    const day = date.getDate()==29 && date.getMonth()==1?28:date.getDay();

    return new Date(date.getFullYear() - 1, date.getMonth(), day);
  },

  /*
  new Date("2021-01-04").getDate() < 7 && new Date("2021-01-04").getDate()-1 < ((new Date("2021-01-04").getDay()+6)%7)
   */

  getWeek(date: Date): number {
    const d: Date = new Date(date);  //Creamos un nuevo Date con la fecha de "this".

    d.setHours(0, 0, 0, 0);   //Nos aseguramos de limpiar la hora.
    d.setDate(d.getDate() + 4 - (d.getDay() || 7)); // Recorremos los días para asegurarnos de estar "dentro de la semana"

    //Finalmente, calculamos redondeando y ajustando por la naturaleza de los números en JS:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
  },

  setHoursToZero(date:Date): Date {
     date.setHours(0,0,0,0);

     return date;
  },

  range(start: number, end: number): number[] {
    const result: number[] = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  },

  /**
   * Se le pasa el formato para fechas semanales. YYYY-WWW
   * @param fecha
   */
  formatNameByWeek(fecha:Date):string {
    return fecha.getMonth()===0 && fecha.getDate() < 7 && fecha.getDate()-1 < ((fecha.getDay()+6)%7)?
      fecha.getFullYear()-1+"-"+  DateUtils.getWeek(fecha):
      fecha.getFullYear()  +"-"+  DateUtils.getWeek(fecha);
  },

  groupInWeek(fecha:Date, store: Store):string {
    const miliSecondDay = 24*60*60*1000;
    const fechaLunes = new Date(fecha.getTime() - ((fecha.getDay()+6)%7*miliSecondDay))
    const dateFormat = store.selectSnapshot(MainState.accountSettings).dateFormat;
    const locale = store.selectSnapshot(MainState.accountSettings).locale;

    return moment(fechaLunes).locale(locale).format(dateFormat);
  },

  formatDate(date: Date, store: Store): string {
    const dateFormat = store.selectSnapshot(MainState.accountSettings).dateFormat;
    const locale = store.selectSnapshot(MainState.accountSettings).locale;

    return moment(date).locale(locale).format(dateFormat);
  },

  formatDateAsFullWeek(date: Date, store: Store): string {
    const dateEnd = date.getTime() + 6 * 24 * 60 * 60 * 1000;
    const dateFormat = store.selectSnapshot(MainState.accountSettings).dateFormat;
    const locale = store.selectSnapshot(MainState.accountSettings).locale;

    return moment(date).locale(locale).format(dateFormat) + "-" + moment(dateEnd).locale(locale).format(dateFormat);
  }

};
