import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AzModalConfig, AzModalRef } from '@azigrene/components';
import { AzNotifierService } from '@azigrene/components';
import { Select, Store } from '@ngxs/store';
import { KeyValue } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import {MainState} from "@app/core/store/main.store";
import {ISteps, StepperComponent} from "@app/shared/components/stepper/stepper.component";
import {AzDownloaderService} from "@azigrene/downloader";

export interface ImportResult {
  fila: number;
  operacion: string;
  errores: any[];
  avisos: any[];
  campo: string;
  mensaje: string;
}

@Component({
  selector: 'app-dialogo-importacion',
  templateUrl: './dialogo-importacion.component.html'
})
export class DialogoImportacionComponent implements OnInit, AfterViewInit {

  @Select(MainState.theme) theme$;
  activeStep = 'tipoFichero';
  steps: ISteps = {
    // seleccion: {
    //   title: 'Selecciona tipo importacion',
    //   canGoBack: true,
    //   disabled: false,
    //   previous: null,
    //   next: 1,
    //   active: true
    // },
    tipoFichero: {
      title: this.translateService.translate('dialogo-importar.seleccionar-fichero'),
      canGoBack: true,
      disabled: false,
      previous: null,
      active: true,
      next: 2,
      nextStepText: this.translateService.translate('misc.import'),
      nextEnabled: (() => this.isFileUploaded).bind(this),
      previousEnabled: (() => true)
    },
    // tipoPaster: {
    //   title: 'Pega datos desde archivo',
    //   canGoBack: true,
    //   disabled: false,
    //   previous: 1,
    //   next: 3
    // },
    resultados: {
      title: this.translateService.translate('dialogo-importar.resultados-tab'),
      canGoBack: false,
      disabled: false,
      previous: 1,
      next: null
    }
  };

  urlBase: string;
  exitPressed = false;
  resultado: any;
  errores: ImportResult[];
  avisos: ImportResult[];
  errorFileProcess: string;
  showErroresAvisos: boolean;
  fileDownload: any;
  hasErrors: boolean;
  cols: any[];
  fileName = this.translateService.translate('dialogo-importar.archivo-no-seleccionado');
  isFileUploaded = false;
  loading = false;
  subscription$: (file, formato?) => Observable<any>;
  @ViewChild('file', {static: true}) file;
  @ViewChild('stepper', {static: true}) stepper: StepperComponent;
  @ViewChild('dropContainer', {static: true}) dropContainer;

  formTipoImportacion: FormGroup;

  originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return 0;
  }

  ngOnInit() {
    this.subscription$ = this.config.data.subscription;
    this.urlBase = this.config.data.urlBase;
    this.cols = [
      {field: 'fila', header: this.translateService.translate('dialogo-importar.fila')},
      {field: 'operacion', header: this.translateService.translate('dialogo-importar.operacion')},
      {field: 'errores', header: this.translateService.translate('dialogo-importar.errores')},
      {field: 'avisos', header: this.translateService.translate('dialogo-importar.avisos')}
    ];
  }

  constructor(private config: AzModalConfig,
              public ref: AzModalRef,
              private translateService: TranslocoService,
              private notifierService: AzNotifierService,
              private formBuilder: FormBuilder,
              private http: HttpClient,
              private store: Store,
              private azDownloaderService: AzDownloaderService) {
    this.formTipoImportacion = formBuilder.group({
      tipoImportacion: ['file', Validators.required]
    });

    this.formTipoImportacion.valueChanges.subscribe(status => {
      if (this.stepper.activeStep === 'seleccion') {
        this.stepper.nextEnabled = this.formTipoImportacion.valid;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.dropContainer) {
      this.dropContainer.nativeElement.ondragover = (evt) => {
        evt.preventDefault();
      };

      this.dropContainer.ondrop = (evt) => {
        this.file.files = evt.dataTransfer.files;
        evt.preventDefault();
      };
    }
  }


  show(event) {
    this.exitPressed = false;
  }

  hide(event) {
    this.exitPressed = true;
  }

  setFile(file) {
    if (file) {
      this.file = file;
      this.fileName = file.name.replace(/.*[\/\\]/, '');
      if (file.value !== '') {
        this.isFileUploaded = true;
        this.stepper.nextEnabled = true;
      } else {
        this.isFileUploaded = false;
      }
    }
  }

  importar() {
    if (this.file) {
      this.loading = true;
      const nombreFichero = this.file.name;
      const formato: string = nombreFichero.substring(nombreFichero.lastIndexOf('.') + 1, nombreFichero.length).toUpperCase();

      this.subscription$(this.file, formato).pipe(take(1)).subscribe((results: any) => {
        this.resultado = results;
        this.fileDownload = results.fileDownload;
        if (results.errorFileProcess != null) {
          this.showErroresAvisos = false;
          this.errorFileProcess = results.errorFileProcess;
        } else {
          this.showErroresAvisos = true;
          this.errores = results.resultado.filter(value => value.operacion === 'ERROR').map((row) => {
            return {
              fila: row.fila,
              operacion: row.operacion,
              errores: row.errores,
              avisos: row.avisos,
              campo: row.campo,
              mensaje: row.mensaje
            };
          });
          this.avisos = results.resultado.filter(value => value.avisos.length > 0).map((row) => {
            return {
              fila: row.fila,
              operacion: row.operacion,
              errores: row.errores,
              avisos: row.avisos,
              campo: row.campo,
              mensaje: row.mensaje
            };
          });
        }

        this.hasErrors = (this.errorFileProcess != null || ((this.errores?.length || this.avisos?.length))) && (formato.toUpperCase() === 'XLS' || formato.toUpperCase() === 'XLSX');
        this.loading = false;
        this.stepper.nextEnabled = true;
      }, () => {
        this.notifierService.add({
          key: 'toast',
          severity: 'error',
          summary: this.translateService.translate('misc.error'),
          detail: this.translateService.translate('dialogo-importar.error-descripcion')
        });
        this.loading = false;
      });
    }
  }


  pasterImport(data: any[]) {
    this.loading = true;
    this.subscription$(data, 'JSON').pipe(take(1)).subscribe((results: any) => {
      this.resultado = results;
      this.errores = results.resultado.filter(value => value.operacion === 'ERROR').map((row) => {
        return {
          data: {
            fila: row.fila,
            operacion: row.operacion,
            errores: row.errores ? row.errores.length : 0,
            avisos: row.avisos ? row.avisos.length : 0
          },
          children: ((row.errores ? row.errores.map(error => {
            return {
              data: {
                fila: error.campo,
                operacion: error.mensaje,
                errores: '',
                avisos: this.translateService.translate('misc.error')
              }
            };
          }) : []).concat(row.avisos ? row.avisos.map(error => {
            return {
              data: {
                fila: error.campo,
                operacion: error.mensaje,
                errores: '',
                avisos: this.translateService.translate('dialogo-importar.aviso')
              }
            };
          }) : []))
        };
      });
      this.loading = false;
    }, () => {
      this.notifierService.add({
        key: 'toast',
        severity: 'error',
        summary: this.translateService.translate('misc.error'),
        detail: this.translateService.translate('dialogo-importar.error-descripcion')
      });
      this.loading = false;
    });
  }

  onNextStep(step: { from: string; to: string }) {
    switch (step.from) {
    case 'seleccion':
      if (this.formTipoImportacion.controls.tipoImportacion.value === 'file') {
        this.stepper.activeStep = 'tipoFichero';
        this.stepper.hideStep('tipoPaster');
        this.stepper.nextEnabled = false;
      } else if (this.formTipoImportacion.controls.tipoImportacion.value === 'paster') {
        this.stepper.activeStep = 'tipoPaster';
        this.stepper.hideStep('tipoFichero');
        this.stepper.nextEnabled = false;
      }

      break;
    case 'tipoFichero':
      if (this.file) {
        this.importar();
        this.stepper.activeStep = 'resultados';
        this.stepper.nextEnabled = false;
      }

      break;
    }

    this.stepper.previousEnabled = false;
  }

  onPrevious(step: { from: string; to: string }) {
    switch (step.from) {
    case 'tipoFichero':
    case 'tipoPaster':
      this.formTipoImportacion.reset();
      this.stepper.activeStep = 'seleccion';
      break;
    }
  }

  downloadDocumento() {
    const binaryString = window.atob(this.fileDownload);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    saveAs(new Blob([bytes], { type: 'application/octet-stream' }), 'Resultados_' + this.fileName );
  }

}
