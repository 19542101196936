import { Component, OnInit } from '@angular/core';
import { es_ES, NzI18nService } from 'ng-zorro-antd/i18n';
import moment from "moment";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'angular-base-template';

  constructor(private i18n: NzI18nService, public translateService: TranslocoService
  ) {

      this.i18n.setLocale(es_ES);
      moment.locale(this.translateService.getActiveLang());
  }

  ngOnInit(): void {

  }
}
