import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {take} from 'rxjs/operators';
import {FormUtils} from "@app/shared/utils";
import {PasswordRecoveryModel} from "@app/shared/model/password-recovery.model";
import {RecoverPasswordService} from "@app/shared/service/recover-password.service";

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html'
})
export class NewPasswordComponent {

  nuevoPasswordForm: FormGroup;
  isSent = false;
  isTokenValid = true;
  passwordRecoveryModel: PasswordRecoveryModel;
  errors: string[] = [];

  constructor(
    public router: Router,
    public store: Store,
    private activatedRoute: ActivatedRoute,
    private recoverPasswordService: RecoverPasswordService,
    private formBuilder: FormBuilder,
    private translateService: TranslocoService) {

    const token = this.activatedRoute.snapshot.params.token;

    this.nuevoPasswordForm = this.formBuilder.group({
      password: [null, {validators: [Validators.required, FormUtils.validators.checkPassword], updateOn: 'change'}],
      repetirPassword: [null, {validators: [Validators.required, FormUtils.validators.checkPassword], updateOn: 'change'}]
    });
    this.recoverPasswordService
      .getRecuperacionPasswordByToken(token)
      .pipe(take(1))
      .subscribe(
        (passwordRecoveryModel: PasswordRecoveryModel) => {
          if (!passwordRecoveryModel || !passwordRecoveryModel.activo) {
            this.isTokenValid = false;
          }

          this.passwordRecoveryModel = passwordRecoveryModel;
        },
        () => {
          this.isTokenValid = false;
        }
      );
  }

  save(): void {
    this.errors = [];
    if (this.nuevoPasswordForm.get('password').value !== this.nuevoPasswordForm.get('repetirPassword').value) {
      this.errors.push(this.translateService.translate('nuevo-login.passwords-no-coinciden'));

      return;
    }

    const tokenParam = this.activatedRoute.snapshot.params.token;

    this.recoverPasswordService
      .aceptarPassword(tokenParam, this.nuevoPasswordForm.get('password').value)
      .pipe(take(1))
      .subscribe(
        (result: boolean) => {
          if (result) {
            this.isSent = true;
          }
        },
        (error: any) => {
          if (error.error.subErrors) {
            for (error of error.error.subErrors) {
              this.errors.push(error.message);
            }
          } else {
            this.errors.push(error.error.message);
          }
        }
      );
  }

}
