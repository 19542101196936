import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { Observable } from 'rxjs';
import {HistoricalModel} from "../model/historical.model";
import {environment} from "@env/environment";
import {ResultadoValidacionModel} from "@app/shared/model/resultado-validacion.model";
import {HistoricalSaleGraphModel} from "@app/shared/model/historical-sale-graph.model";
import {StatisticHistoricalModel} from "@app/shared/model/statistic-historical.model";

@Injectable()
export class HistoricalService {

  urlBase = environment.api.base_url + environment.api.modules.historico_ventas;

  constructor(private http: HttpClient) {
  }

  getAllPaginated(searchRequest: SearchRequest): Observable<PaginatedResponse<HistoricalModel>> {
    return this.http.post<PaginatedResponse<HistoricalModel>>(this.urlBase + '/all', searchRequest);
  }

  groupStatistic(productoId:number, tiendaId:number): Observable<StatisticHistoricalModel> {
    const historical = {productoId: productoId, tiendaId: tiendaId};
    return this.http.post<StatisticHistoricalModel>(this.urlBase + `/group_statistic`,historical);
  }

  groupByMonth(productoId:number, tiendaId:number): Observable<HistoricalModel[]> {
    return this.groupByMonthFecha(productoId, tiendaId, null, null);
  }

  groupByMonthFecha(productoId: number, tiendaId: number, fechaInicio: Date, fechaFin: Date): Observable<HistoricalModel[]> {
    const historical = {productoId: productoId, tiendaId: tiendaId, fechaInicio: fechaInicio, fechaFin: fechaFin};
    return this.http.post<HistoricalModel[]>(this.urlBase + `/group_by_month`,historical);
  }

  groupByWeek(productoId:number, tiendaId:number): Observable<HistoricalModel[]> {
    const historical = {productoId: productoId, tiendaId:tiendaId};
    return this.http.post<HistoricalModel[]>(this.urlBase + `/group_by_week`,historical);
  }

  byHour(request:HistoricalModel): Observable<HistoricalModel[]> {
    return this.http.post<HistoricalModel[]>(this.urlBase + `/by_hour`,request);
  }

  create(historical: HistoricalModel): Observable<HistoricalModel> {
    return this.http.post<HistoricalModel>(this.urlBase + '/create', historical);
  }

  update(historical: HistoricalModel):  Observable<HistoricalModel> {
    return this.http.put<HistoricalModel>(this.urlBase + '/' + historical.id + '/update', historical);
  }

  fetchTopUnidades(year:number, pageSize: number, tiendaId:number): Observable<HistoricalSaleGraphModel[]> {
    if(tiendaId != null) {
      return this.http.put<HistoricalSaleGraphModel[]>(this.urlBase + "/" + year + "/" + pageSize + '/group_by_top_unidades/store/'+tiendaId, {});
    } else {
      return this.http.put<HistoricalSaleGraphModel[]>(this.urlBase + "/" + year + "/" + pageSize + '/group_by_top_unidades', {});
    }
  }

  fetchTopImporte(year:number, pageSize: number, tiendaId:number): Observable<HistoricalSaleGraphModel[]> {
    if (tiendaId != null) {
      return this.http.put<HistoricalSaleGraphModel[]>(this.urlBase + "/" + year + "/" + pageSize + '/group_by_top_importe/store/' + tiendaId, {});
    } else {
      return this.http.put<HistoricalSaleGraphModel[]>(this.urlBase + "/" + year + "/" + pageSize + '/group_by_top_importe', {});
    }
  }

  export(searchRequest: SearchRequest, formato?: string): Observable<unknown> {
    if (formato) {
      return this.http.post(this.urlBase + '/export/' + formato, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    } else {
      return this.http.post(this.urlBase + '/export', searchRequest, { observe: 'response', responseType: 'blob' });
    }
  }

  import(file: unknown, formato?: string): Observable<ResultadoValidacionModel> {
    if (formato) {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import/' + formato, file);
    } else {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import', file);
    }
  }

}
