import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { Store } from '@ngxs/store';
import {AuthState} from "@app/core/store/auth.store";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private store: Store) {
  }

  canActivate(): boolean {
    if (this.store.selectSnapshot(AuthState.isAdminUser)) {
      return true;
    } else {
      return false;
    }
  }

}
