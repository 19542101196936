import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutes } from './auth.routes';

import { TranslocoModule } from '@ngneat/transloco';
import {OAuthModule} from "angular-oauth2-oidc";
import {SharedModule} from "@app/shared/shared.module";
import {LoginComponent} from "@app/auth/pages/login/login.component";
import {AuthService} from "@app/auth/services/auth.service";


@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, SharedModule, AuthRoutes, TranslocoModule, OAuthModule.forRoot()],
  providers: [AuthService],
})
export class AuthModule {}
