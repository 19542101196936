import { Action, Selector, State, StateContext } from '@ngxs/store';
import {Injectable} from "@angular/core";
import {AvailableThemes, UsuarioModel} from "@app/shared/model/usuario.model";
import {cloneObject} from "@azigrene/utils";
import {StoreModel} from "@app/shared/model/store.model";
import {CuentaModel} from "@app/shared/model/cuenta.model";
import {AccountTypeModel} from "@app/shared/model/account-type.model";
import {AccountTypeEnum} from "@app/shared/enum/account-type.enum";
import {LoginModel} from "@app/shared/model/login.model";

export interface AccountSettings {
  locale: string;
  currencyCode: string;
  dateFormat: string;
  nzDateFormat: string;
  nzDateTimeFormat: string;
  monthFormat: string;
  nzMonthFormat: string;
  hourFormat: string;
  timezone: string;
  numberRegex: RegExp;
}

export const defaultAccountSettings: AccountSettings = {
  locale: 'es',
  dateFormat: 'DD/MM/YYYY',
  currencyCode: '€',
  nzDateFormat: 'dd/MM/yyyy',
  nzDateTimeFormat: 'dd/MM/yyyy HH:mm',
  monthFormat: 'MM/yyyy',
  nzMonthFormat: 'MMM',
  hourFormat: 'HH:mm',
  timezone: 'Europe/Madrid',
  numberRegex: /^[0-9]+(\,[0-9]*){0,1}$/
};

export class FillLogin {

  static readonly type = '[Main] FillLogin]';

  constructor(public readonly login?: LoginModel) {
  }

}

export class FillUsuario {

  static readonly type = '[Main] FillUsuario]';

  constructor(public readonly usuario?: UsuarioModel) {
  }

}

export class FillCuenta {

  static readonly type = '[Main] FillCuenta]';

  constructor(public readonly cuenta?: CuentaModel) {
  }

}

export class FillTipoCuenta {

  static readonly type = '[Main] FillTipoCuenta]';

  constructor(public readonly tipoCuenta?: AccountTypeModel) {
  }
}

export class FillTienda {

  static readonly type = '[Main] FillTienda]';

  constructor(public readonly tienda?: StoreModel) {
  }

}

export class DeleteTienda {

  static readonly type = '[Main] class DeleteTienda' ;

  constructor(public readonly tienda?: StoreModel) {
  }

}

export interface MainStateModel {
  usuario: UsuarioModel;
  login: LoginModel;
  cuenta: CuentaModel;
  tipoCuenta: AccountTypeModel;
  preferenciasUsuario: {
    [id: string]: PreferenciasUsuario;
  };
}

export type PreferenciasUsuario = {
  login: LoginModel,
  cuenta: CuentaModel;
  tienda: StoreModel;
  settings: AccountSettings;
  usuario: UsuarioModel;
}

@Injectable()
@State<MainStateModel>({
  name: 'main',
  defaults: {
    login: null,
    usuario: null,
    cuenta: null,
    tipoCuenta: null,
    preferenciasUsuario: {},
  }
})


export class MainState {

  @Selector()
  static usuario(state: MainStateModel): UsuarioModel {
    return state.usuario;
  }

  @Selector()
  static cuenta(state: MainStateModel): CuentaModel {
    return state.cuenta;
  }

  @Selector()
  static tipoCuenta(state: MainStateModel): AccountTypeModel {
    return state.tipoCuenta;
  }

  @Selector()
  static tienda(state: MainStateModel): StoreModel {
    return state.preferenciasUsuario[state.usuario.id].tienda;
  }

  @Selector()
  static theme(state: MainStateModel): AvailableThemes {
    return state.preferenciasUsuario[state.usuario.id] ? state.preferenciasUsuario[state.usuario.id].usuario.theme : null;
  }

  @Selector()
  static accountSettings(state: MainStateModel): AccountSettings {
    //return state.preferenciasUsuario[state.usuario.id].settings || defaultAccountSettings;
    return defaultAccountSettings;
  }

  @Selector()
  static isCuentaConsumo(state: MainStateModel): boolean {
    return state.tipoCuenta?.codigo == AccountTypeEnum.CONSUMO;
  }

  @Selector()
  static isCuentaVentas(state: MainStateModel): boolean {
    return state.tipoCuenta?.codigo == AccountTypeEnum.VENTAS;
  }

  @Action(FillTipoCuenta)
  fillTipoCuenta(ctx: StateContext<MainStateModel>, action: FillTipoCuenta): void {
    ctx.patchState({
      tipoCuenta: action.tipoCuenta
    });
  }

  @Action(FillCuenta)
  fillCuenta(ctx: StateContext<MainStateModel>, action: FillCuenta): void {
    const preferenciasUsuarioMod: {[id: string]: PreferenciasUsuario} = cloneObject(ctx.getState().preferenciasUsuario);
    const loginId = ctx.getState().usuario.id;
    const usuario = ctx.getState().usuario;
    const login = ctx.getState().login;

    if (preferenciasUsuarioMod[loginId]) {
      preferenciasUsuarioMod[loginId].cuenta = action.cuenta;
    } else {
      preferenciasUsuarioMod[loginId] = {
        login: login,
        cuenta: action.cuenta,
        settings: defaultAccountSettings,
        usuario: usuario,
        tienda: null
      };
    }

    ctx.patchState({
      cuenta: action.cuenta,
      preferenciasUsuario: preferenciasUsuarioMod,
      tipoCuenta: action.cuenta.tipoCuenta
    });
  }

  @Action(FillLogin)
  fillLogin(ctx: StateContext<MainStateModel>, action: FillLogin): void {
    ctx.patchState({
      login: action.login
    });
  }

  @Action(FillUsuario)
  fillUsuario(ctx: StateContext<MainStateModel>, action: FillUsuario): void {
    ctx.patchState({
      usuario: action.usuario
    });
  }

  @Action(FillTienda)
  fillTienda(ctx: StateContext<MainStateModel>, action: FillTienda): void {
    const preferenciasUsuarioMod = cloneObject(ctx.getState().preferenciasUsuario);
    const loginId = ctx.getState().usuario.id;

    if (preferenciasUsuarioMod[loginId]) {
      preferenciasUsuarioMod[loginId].tienda = action.tienda;

      ctx.patchState({
        preferenciasUsuario: preferenciasUsuarioMod
      });
    }
  }

  @Action(DeleteTienda)
  deleteTienda(ctx: StateContext<MainStateModel>): void {
    const preferenciasUsuarioMod = cloneObject(ctx.getState().preferenciasUsuario);
    const loginId = ctx.getState().usuario.id;

    if (preferenciasUsuarioMod[loginId]) {
      preferenciasUsuarioMod[loginId].tienda = null;

      ctx.patchState({
        preferenciasUsuario: preferenciasUsuarioMod
      });
    }
  }

}
