import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable()
export class RecoverPasswordService {

  constructor(private http: HttpClient) { }

  recuperarPassword(email: string) {
    return this.http.post<boolean>(environment.api.recover_password, email);
  }

  getRecuperacionPasswordByToken(token: string) {
    return this.http.get(environment.api.recover_password + '/' + token + '/one');
  }

  aceptarPassword(token: string, password: string) {
    return this.http.post<boolean>(environment.api.recover_password + '/aceptar-password', {token: token, password: password});
  }

}
