import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {AuthState} from "@app/core/store/auth.store";

const components = [];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgxsModule.forFeature([AuthState]),
    AngularSvgIconModule.forRoot()
  ],
  exports: [...components],
  providers: []
})
export class CoreModule { }
