import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CalendarUseTypeDayModel} from "@app/shared/model/calendar-use-type-day.model";

@Injectable()
export class CalendarUseTypeDayService {

  urlBase = environment.api.base_url + environment.api.modules.calendar_use_type_day;

  constructor(private http: HttpClient) {
  }

  getAll(calendarUseId: number) {
    return this.http.get<Array<CalendarUseTypeDayModel>>(this.urlBase + '/' + calendarUseId + '/all');
  }

  get(calendarUseTypeDayId: number): Observable<CalendarUseTypeDayModel> {
    return this.http.get<CalendarUseTypeDayModel>(this.urlBase + '/' + calendarUseTypeDayId + '/one');
  }

  create(calendarUseTypeDayModel: CalendarUseTypeDayModel): Observable<CalendarUseTypeDayModel> {
    return this.http.post<CalendarUseTypeDayModel>(this.urlBase + '/create', calendarUseTypeDayModel);
  }

  update(calendarUseTypeDayModel: CalendarUseTypeDayModel): Observable<CalendarUseTypeDayModel> {
    return this.http.put<CalendarUseTypeDayModel>(this.urlBase + '/' + calendarUseTypeDayModel.id + '/update', calendarUseTypeDayModel);
  }

  delete(calendarUseTypeDayId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + calendarUseTypeDayId + '/delete');
  }

  checkCalendarUseTypeDay(calendarUseTypeDayModel: CalendarUseTypeDayModel, calendarioUsosId: number): Observable<boolean> {
    return this.http.post<boolean>(this.urlBase + '/' + calendarioUsosId + '/check_calendario_uso', calendarUseTypeDayModel);
  }

}
