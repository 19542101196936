import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Injectable} from "@angular/core";
import {PredictionProcessModel} from "@app/shared/model/prediction-process.model";
import {Observable} from "rxjs";

@Injectable()
export class PredictionProcessService {

  urlBase = environment.api.base_url + environment.api.modules.process_prediction;
  constructor(private http: HttpClient) { }

  getAllPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<PredictionProcessModel>>(this.urlBase + '/all', searchRequest);
  }

  pause(prediccionProcesoId?: number): Observable<PredictionProcessModel> {
    return this.http.get<PredictionProcessModel>(this.urlBase + '/pause/'+prediccionProcesoId );
  }

  restart(prediccionProcesoId?: number): Observable<PredictionProcessModel> {
    return this.http.get<PredictionProcessModel>(this.urlBase + '/restart/'+prediccionProcesoId);
  }

  delete(prediccionProcesoId?: number): Observable<PredictionProcessModel> {
    return this.http.get<PredictionProcessModel>(this.urlBase + '/delete/'+prediccionProcesoId);
  }

}
