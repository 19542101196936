import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Store} from '@ngxs/store';
import moment from 'moment';
import {MainState} from "@app/core/store/main.store";
import {DateUtils} from "@app/shared/utils";

@Component({
  selector: 'app-date-range-selector',
  template: `
    <div class="az-form-control">
      <ng-container *ngIf="disabledStart || disabledEnd">
        <ng-container *ngIf="type === 'day'">
          <div class="inline-flex space-x-3 w-full">
            <div class="flex-1">
              <nz-date-picker
                *ngIf="type == 'day'"
                id="date-range-selector.range-start"
                [nzFormat]="_dateFormat"
                (ngModelChange)="_dates.start = $event; emitValue()"
                [disabled]="disabled || disabledStart"
                [(ngModel)]="_dates.start"
                [nzDisabledDate]="disabledStartDate"
                class="w-full"
              ></nz-date-picker>
            </div>
            <div class="flex-1">
              <nz-date-picker
                *ngIf="type == 'day'"
                id="date-range-selector.range-end"
                nzPlaceHolder=""
                [nzAllowClear]="true"
                [nzFormat]="_dateFormat"
                (ngModelChange)="_dates.end = $event; emitValue()"
                [disabled]="disabled || disabledEnd"
                [(ngModel)]="_dates.end"
                [nzDisabledDate]="disabledEndDate"
                class="w-full"
              ></nz-date-picker>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="type === 'month'">
          <div class="inline-flex space-x-3 w-full">
            <div class="flex-1">
              <nz-month-picker
                *ngIf="type == 'month'"
                id="date-range-selector.range-month-start"
                [nzFormat]="_monthFormat"
                (ngModelChange)="_dates.start = $event; emitValue()"
                [disabled]="disabled || disabledStart"
                [(ngModel)]="_dates.start"
                [nzDisabledDate]="disabledStartDate"
                class="w-full"
              ></nz-month-picker>
            </div>
            <div class="flex-1">
              <nz-month-picker
                *ngIf="type == 'month'"
                id="date-range-selector.range-month-end"
                [nzFormat]="_monthFormat"
                (ngModelChange)="_dates.end = $event; emitValue()"
                [disabled]="disabled || disabledEnd"
                [(ngModel)]="_dates.end"
                [nzDisabledDate]="disabledEndDate"
                class="w-full"
              ></nz-month-picker>
            </div>
          </div>
        </ng-container>

      </ng-container>
      <ng-container *ngIf="!disabledStart && !disabledEnd">
        <ng-container *ngIf="type === 'day'">
          <ng-container *ngIf="!mandatoryRange">
            <div class="flex justify-between" *ngIf="!textBottom">
              <small
                [innerHTML]="
              _dates.end
                ? ('date-range-selector.has-range-end'
                  | transloco: { start: _dates.start | localizedDate, end: _dates.end | localizedDate, days: _dates.end | amDifference: _dates.start:'days' | localizedNumber })
                : ('date-range-selector.no-range-end' | transloco: { start: _dates.start | localizedDate })
            "
                class=" block mb-1 text-xs text-gray-400"
              >
              </small>
              <a class="text-xs ml-1" *ngIf="!_hasEnd" (click)="inputRangeEnd(true)"> {{ 'date-range-selector.set-range-end' | transloco }}</a>
              <a class="text-xs ml-1" *ngIf="_hasEnd" (click)="inputRangeEnd(false)"> {{ 'date-range-selector.unset-range-end' | transloco }}</a>
            </div>
            <div class="inline-flex space-x-3 w-full">
              <div class="flex-1">
                <nz-date-picker
                  *ngIf="type == 'day'"
                  id="date-range-selector.range-start"
                  [nzFormat]="_dateFormat"
                  (ngModelChange)="_dates.start = $event; emitValue()"
                  [disabled]="disabled"
                  [(ngModel)]="_dates.start"
                  [nzDisabledDate]="disabledStartDate"
                  class="w-full"
                ></nz-date-picker>
                <nz-month-picker
                  *ngIf="type == 'month'"
                  id="date-range-selector.range-month-start"
                  [nzFormat]="_monthFormat"
                  (ngModelChange)="_dates.start = $event; emitValue()"
                  [disabled]="disabled"
                  [(ngModel)]="_dates.start"
                  [nzDisabledDate]="disabledStartDate"
                  class="w-full"
                ></nz-month-picker>
              </div>
              <div class="flex-1">
                <nz-date-picker
                  *ngIf="type == 'day'"
                  id="date-range-selector.range-end"
                  nzPlaceHolder=""
                  [nzAllowClear]="true"
                  [nzFormat]="_dateFormat"
                  (ngModelChange)="_dates.end = $event; emitValue()"
                  [disabled]="disabled || !_hasEnd"
                  [(ngModel)]="_dates.end"
                  [nzDisabledDate]="disabledEndDate"
                  class="w-full"
                ></nz-date-picker>
                <nz-month-picker
                  *ngIf="type == 'month'"
                  id="date-range-selector.range-month-end"
                  [nzFormat]="_monthFormat"
                  (ngModelChange)="_dates.start = $event; emitValue()"
                  [disabled]="disabled"
                  [(ngModel)]="_dates.start"
                  [nzDisabledDate]="disabledStartDate"
                  class="w-full"
                ></nz-month-picker>
              </div>
            </div>
            <div class="flex justify-between" *ngIf="textBottom">
              <small
                [innerHTML]="
              _dates.end
                ? ('date-range-selector.has-range-end'
                  | transloco: { start: _dates.start | localizedDate, end: _dates.end | localizedDate, days: _dates.end | amDifference: _dates.start:'days' | localizedNumber })
                : ('date-range-selector.no-range-end' | transloco: { start: _dates.start | localizedDate })
            "
                class=" block mb-1 text-xs text-gray-400"
              >
              </small>
              <a class="text-xs ml-1" *ngIf="!_hasEnd" (click)="inputRangeEnd(true)"> {{ 'date-range-selector.set-range-end' | transloco }}</a>
              <a class="text-xs ml-1" *ngIf="_hasEnd" (click)="inputRangeEnd(false)"> {{ 'date-range-selector.unset-range-end' | transloco }}</a>
            </div>
          </ng-container>
          <ng-container *ngIf="mandatoryRange">
            <div class="inline-flex space-x-3 w-full">
              <nz-range-picker
                [nzAllowClear]="true"
                [nzFormat]="_dateFormat"
                [disabled]="disabled"
                class="flex-1"
                id="date-range-selector.range"
                [ngModel]="_dates?.start == null ? [] : [_dates.start, _dates.end]"
                (ngModelChange)="onChangeRange($event[0], $event[1])"
                [nzDisabledDate]="disabledRange"
              >
              </nz-range-picker>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="type === 'month'">
          <div class="flex justify-between" *ngIf="!textBottom">
            <small
              *ngIf="!mandatoryRange ? _dates && _dates.start : _dates && _dates.start && _dates.end"
              [innerHTML]="
            _dates.end
              ? ('date-range-selector.has-range-end'
                | transloco
                  : {
                      start: moment(_dates.start).startOf(type) | localizedDate,
                      end: moment(_dates.end).endOf(type) | localizedDate,
                      days: _dates.end | amDifference: _dates.start:'days' | localizedNumber
                    })
              : ('date-range-selector.no-range-end' | transloco: { start: _dates.start | localizedDate })
          "
              class=" block mb-1 text-xs text-gray-400"
            >
            </small>
            <a class="text-xs ml-1" *ngIf="!mandatoryRange && !_hasEnd" (click)="inputRangeEnd(true)"> {{ 'date-range-selector.set-range-end' | transloco }}</a>
            <a class="text-xs ml-1" *ngIf="!mandatoryRange && _hasEnd" (click)="inputRangeEnd(false)"> {{ 'date-range-selector.unset-range-end' | transloco }}</a>
          </div>
          <div class="inline-flex space-x-3 w-full">
            <div class="flex-1">
              <nz-month-picker
                *ngIf="type == 'month'"
                id="date-range-selector.range-month-start"
                [nzFormat]="_monthFormat"
                (ngModelChange)="_dates.start = $event; emitValue()"
                [disabled]="disabled"
                [(ngModel)]="_dates.start"
                [nzDisabledDate]="disabledStartDate"
              ></nz-month-picker>
            </div>
            <div class="flex-1">
              <nz-month-picker
                *ngIf="type == 'month'"
                id="date-range-selector.range-month-end"
                [nzFormat]="_monthFormat"
                (ngModelChange)="_dates.end = $event; emitValue()"
                [disabled]="disabled"
                [(ngModel)]="_dates.end"
                [nzDisabledDate]="disabledEndDate"
              ></nz-month-picker>
            </div>
          </div>
          <div class="flex justify-between" *ngIf="textBottom">
            <small
              *ngIf="!mandatoryRange ? _dates && _dates.start : _dates && _dates.start && _dates.end"
              [innerHTML]="
            _dates.end
              ? ('date-range-selector.has-range-end'
                | transloco
                  : {
                      start: moment(_dates.start).startOf(type) | localizedDate,
                      end: moment(_dates.end).endOf(type) | localizedDate,
                      days: moment(_dates.end).endOf(type) | amDifference: moment(_dates.start).startOf(type):'days' | localizedNumber
                    })
              : ('date-range-selector.no-range-end' | transloco: { start: _dates.start | localizedDate })
          "
              class=" block mb-1 text-xs text-gray-400"
            >
            </small>
            <a class="text-xs ml-1" *ngIf="!mandatoryRange && !_hasEnd" (click)="inputRangeEnd(true)"> {{ 'date-range-selector.set-range-end' | transloco }}</a>
            <a class="text-xs ml-1" *ngIf="!mandatoryRange && _hasEnd" (click)="inputRangeEnd(false)"> {{ 'date-range-selector.unset-range-end' | transloco }}</a>
          </div>
        </ng-container>
      </ng-container>
    </div>

  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeSelectorComponent),
      multi: true,
    },
  ],
})
export class DateRangeSelectorComponent implements OnInit, ControlValueAccessor {

  moment = moment;

  @Input() mandatoryRange = false;
  @Input() textBottom = false;
  @Input() type: 'month' | 'day' = 'day';
  @Input() disabled = false;
  @Input() disabledStart = false;
  @Input() disabledEnd = false;
  @Input() disabledDateStart = null;
  @Input() disabledDateEnd = null;

  dates: Date[] = [];
  _dates: {
    start: Date;
    end: Date;
  } = {
    start: null,
    end: null,
  };

  _dateFormat: string;
  _monthFormat: string;
  _hasEnd = false;

  onChange = (_: Date[]) => {
  };
  onTouch = () => {
  };

  constructor(private store: Store) {
  }

  ngOnInit() {
    this._dateFormat = this.store.selectSnapshot(MainState.accountSettings).nzDateFormat;
    this._monthFormat = this.store.selectSnapshot(MainState.accountSettings).nzDateFormat;
    if (this.mandatoryRange) {
      this._hasEnd = true;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.dates = obj;
    this.populateValue(obj);
  }

  emitValue(): void {
    this.onChange([
      this._dates.start ? DateUtils.withoutTimezone(moment(this._dates.start).startOf(this.type).toDate()) : null,
      this._dates.end ? DateUtils.withoutTimezone(moment(this._dates.end).endOf(this.type).toDate()) : null,
    ]);
  }

  private populateValue(value) {
    if (value && value.length) {
      this._dates = {
        start: value[0],
        end: value[1],
      };
      this._hasEnd = this._dates.end != undefined;
    }
  }

  onChangeRange(start: any, end: any) {
    this._dates = {
      start,
      end,
    };
    this.emitValue();
  }

  inputRangeEnd(hasEndRange: any) {
    if (!hasEndRange) {
      this._dates.end = null;
      this._hasEnd = false;
      this.emitValue();
    } else {
      this._hasEnd = true;
    }
  }

  disabledStartDate = (d: Date) => {
    if (!this._dates) {
      return false;
    }

    if (!this._dates.end) {
      return false;
    }

    const date = new Date(this._dates.end.getFullYear(), this._dates.end.getMonth(), this._dates.end.getDate());

    if (this.type == "month") {
      date.setDate(1);
      date.setMonth(this._dates.end.getMonth() + 1);
    }

    return DateUtils.isBefore(this._dates.end, d);
  };

  disabledEndDate = (d: Date) => {
    if (!this._dates) {
      return false;
    }

    if (!this._dates.start) {
      return false;
    }

    const date = new Date(this._dates.start.getFullYear(), this._dates.start.getMonth(), this._dates.start.getDate());

    if (this.type == "month") {
      date.setDate(1);
      date.setMonth(this._dates.start.getMonth() + 1);
    }

    return DateUtils.isBefore(d, date);
  };

  disabledRange = (d: Date) => {
    let iniReturn = false;
    let finReturn = false;
    const dateCalendar: Date = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    if(this.disabledDateStart) {
      const date = new Date(this.disabledDateStart);
      const dateStart: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      iniReturn =  DateUtils.isBefore(dateCalendar, dateStart);
    }

    if(this.disabledDateEnd) {
      const date = new Date(this.disabledDateEnd);
      const dateEnd: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      finReturn =  DateUtils.isAfterOrSame(d, dateEnd);
    }

    return iniReturn || finReturn;
  }

}
