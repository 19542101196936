import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {UsuarioResponse, LoginResponse} from "@app/auth/model/login";
import {UsuarioModel} from "@app/shared/model/usuario.model";
import {PreferenciasUsuario} from "@app/core/store/main.store";
import {CuentaModel} from "@app/shared/model/cuenta.model";
import {LoginModel} from "@app/shared/model/login.model";

export interface AuthStateModel {
  token: string;
  user: UsuarioModel;
  login: LoginModel;
  cuenta: CuentaModel;
  preferenciasUsuario: {
    [id: string]: PreferenciasUsuario;
  }
}

export class LoginSuccessful {

  static readonly type = '[Auth] LoginSuccessful';

  constructor(public readonly login?: LoginResponse) {
  }

}

export class CreateUserSuccessful {

  static readonly type = '[Auth] UpdateUserSuccessful';

  constructor(public readonly user?: LoginResponse) {
  }

}

export class UpdateUserSuccessful {

  static readonly type = '[Auth] UpdateUserSuccessful';

  constructor(public readonly user?: UsuarioResponse) {
  }

}

export class LogoutAction {

  static readonly type = '[Auth] LogoutAction';

}

@Injectable()
@State<AuthStateModel>({
  name: 'az_ml_auth',
  defaults: {
    token: null,
    user: null,
    login: null,
    cuenta: null,
    preferenciasUsuario: {},
  }
})
export class AuthState {

  @Selector()
  static token$(ctx: AuthStateModel): string {
    return ctx.token;
  }

  @Selector()
  static user$(ctx: AuthStateModel): UsuarioModel {
    return ctx.user;
  }

  @Selector()
  static login$(ctx: AuthStateModel): LoginModel {
    return ctx.login;
  }

  @Selector()
  static isAdminUser(ctx: AuthStateModel): boolean {
    return ctx.user?.rol == 'ADMIN';
  }

  @Action(LoginSuccessful)
  loginSuccessful(ctx: StateContext<AuthStateModel>, action: LoginSuccessful): void {
    ctx.patchState({
      token: action.login.token,
      login: action.login
    });
  }

  @Action(CreateUserSuccessful)
  createUserSuccessful(ctx: StateContext<AuthStateModel>, action: CreateUserSuccessful): void {
    ctx.getState().user = {
      id: action.user.usuarioId,
      nombre: action.user.nombre,
      rol: action.user.rol,
      email: action.user.email,
      theme: null,
      cuentas: action.user.cuentas
    };
  }

  @Action(UpdateUserSuccessful)
  updateUserSuccessful(ctx: StateContext<AuthStateModel>, action: UpdateUserSuccessful): void {
    ctx.getState().user.email = action.user.email;
    ctx.getState().user.nombre = action.user.nombre;
  }

  @Action(LogoutAction)
  logout(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({
      token: null,
      user: null,
      login: null
    });
  }

}
