import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import {AuthState} from "@app/core/store/auth.store";
import {MainState} from "@app/core/store/main.store";

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot([AuthState, MainState], { developmentMode: false }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['az_ml_auth', 'main.usuario', 'main.preferenciasUsuario', 'main.cuenta', 'main.tipoCuenta'],
    }),
  ],
  providers: [],
})
export class StoreModule {}
