import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {ProductModel} from "@app/shared/model/product.model";
import {Observable} from "rxjs";
import {ResultadoValidacionModel} from "@app/shared/model/resultado-validacion.model";
import {StoreModel} from "@app/shared/model/store.model";

@Injectable()
export class ProductService {

  constructor(private http: HttpClient) {
  }

  urlBase = environment.api.base_url + environment.api.modules.product;

  getAllPaginated(searchRequest: SearchRequest): Observable<PaginatedResponse<ProductModel>> {
    return this.http.post<PaginatedResponse<ProductModel>>(this.urlBase + '/all', searchRequest);
  }

  getAllSearch(searchRequest: SearchRequest): Observable<ProductModel[]> {
    return this.http.post<ProductModel[]>(this.urlBase + '/all_search', searchRequest);
  }

  getLastFive(tiendaId?: number): Observable<ProductModel[]> {
    if(tiendaId != null) {
      return this.http.post<ProductModel[]>(this.urlBase + '/last_five/store/'+tiendaId, {});
    } else {
      return this.http.post<ProductModel[]>(this.urlBase + '/last_five', {});
    }
  }

  getAllByStore(tiendaId: number): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(this.urlBase + '/' + tiendaId + '/all_by_store');
  }

  get(productoId: number): Observable<ProductModel> {
    return this.http.get<ProductModel>(this.urlBase + '/' + productoId + '/one');
  }

  update(product: ProductModel, tiendaIds: number[], searchRequest: SearchRequest): Observable<ProductModel> {
    return this.http.put<ProductModel>(this.urlBase + '/' + product.id + '/update', {...product, tiendaIds: tiendaIds, tiendaSearchRequest: searchRequest});
  }

  create(product: ProductModel, tiendaIds: number[], searchRequest: SearchRequest): Observable<ProductModel> {
    return this.http.post<ProductModel>(this.urlBase + '/create', {...product, tiendaIds: tiendaIds, tiendaSearchRequest: searchRequest});
  }

  delete(productoId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + productoId + '/delete');
  }

  export(searchRequest: SearchRequest, formato?: string): Observable<unknown> {
    if (formato) {
      return this.http.post(this.urlBase + '/export/' + formato, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    } else {
      return this.http.post(this.urlBase + '/export', searchRequest, { observe: 'response', responseType: 'blob' });
    }
  }

  import(file: unknown, formato?: string): Observable<ResultadoValidacionModel> {
    if (formato) {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import/' + formato, file);
    } else {
      return this.http.post<ResultadoValidacionModel>(this.urlBase + '/import', file);
    }
  }

}
