import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "@env/environment";
import {UsuarioModel} from "@app/shared/model/usuario.model";
import {PaginatedResponse, SearchRequest} from "@azigrene/searchrequest";
import {Observable} from "rxjs";
import {UsuarioCreateModel} from "@app/shared/model/usuario-create.model";

@Injectable()
export class UserService {

  urlBase = environment.api.base_url + environment.api.modules.user;

  constructor(private http: HttpClient) {
  }

  getAllPaginated(searchRequest: SearchRequest):Observable<PaginatedResponse<UsuarioModel>> {
    return this.http.post<PaginatedResponse<UsuarioModel>>(this.urlBase + '/all', searchRequest);
  }

  public one():Observable<UsuarioModel> {
    return this.http.get<UsuarioModel>(`${this.urlBase}/one`);
  }

  create(usuario: UsuarioCreateModel): Observable<UsuarioModel> {
    return this.http.post<UsuarioModel>(this.urlBase + '/create', usuario);
  }

  public update(data: any) {
    return this.http.put<UsuarioModel>(`${this.urlBase}/update`, data);
  }

  public updateAdmin(usuario: UsuarioModel) {
    return this.http.put<UsuarioModel>(`${this.urlBase}/update-admin`, usuario);
  }

  delete(userId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.urlBase + '/' + userId + '/delete');
  }

  public updatePassword(data: { oldPassword: string, newPassword: string }) {
    return this.http.put<UsuarioModel>(`${this.urlBase}/update-password`, data);
  }

  public updatePasswordAdmin(data: UsuarioCreateModel) {
    return this.http.put<UsuarioModel>(`${this.urlBase}/${data.id}/update-password-admin`, {newPassword:data.password});
  }

}
